import React from "react";
import "./index.css";

const DataCountPopup = ({ data }) => {
  return (
    <div className="DataCount_div">
      <p className="DataCount_text">
        {data?.title?.page_item_count * data?.title?.current_page -
          data?.title?.page_item_count +
          1 || 0}{" "}
        -{" "}
        {Math.min(
          data?.total_count,
          data?.title?.page_item_count * data?.title?.current_page
        ) || 0}{" "}
        of {data?.total_count?.toLocaleString() || 0}
      </p>
    </div>
  );
};

export default DataCountPopup;
