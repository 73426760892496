import React, { useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import { Box, TextField, Tooltip, tooltipClasses } from "@mui/material";
import SearchBar from "../../../components/_utils/search/SearchBar";
import {
  beneTxnViewAllAcc,
  setToggle,
  setIsApprovalStatusSelected,
} from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import DataCountPopup from "../../../components/_utils/DataCountPopup/index";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import { BeneTxnRequireApprovalPage } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import closeBtnImg from "../../../assets/CategoryFilterIcons/cross.png";
import rentalImg from "../../../assets/CategoryFilterIcons/rental.png";
import InfiniteScroll from "react-infinite-scroll-component";
import BeneTxnViewAllCard from "../../../components/BeneTransaction/BeneTxnViewAllCard";
import noaccount from "../../../assets/Beneficiary Icons/noaccfound.png";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import { useLocation, useNavigate } from "react-router-dom";
import BeneTxnStatusPopover from "../../../components/BeneTransaction/BeneTxnStatusPopover";
import BeneTxnFilterSidebar from "../../../components/BeneTransaction/BeneTxnFilterSidebar";
import arrowup from "../../../assets/OwnerAccount/arrowup.png";
import arrowForward from "../../../assets/Beneficiary Icons/arrowForward.png";
import arrowdown from "../../../assets/OwnerAccount/arrowdown1.png";
import ArrowDown8 from "../../../assets/OwnerAccount/arrowdown8.png";
import FilterBlue from "../../../assets/OwnerAccount/filterblue.png";
import Filter from "../../../assets/ViewAllTransactions/filter.png";
import ArrowUp4 from "../../../assets/OwnerAccount/arrowdown4png.png";
import ArrowLeft from "../../../assets/OwnerAccount/arrowleft.png";
import ArrowRight from "../../../assets/OwnerAccount/arrowright.png";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import GridBlue from "../../../assets/OwnerAccount/gridview-blue.png";
import GridWhite from "../../../assets/OwnerAccount/gridview-white.png";
import ListBlue from "../../../assets/OwnerAccount/listview-blue.png";
import ListWhite from "../../../assets/OwnerAccount/listview-white.png";
import DistributionStatusPopover from "../../../components/BeneTransaction/DistributionStatusPopover";
import TxnSearchBar from "../../../components/_utils/TxnSearchBar";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import BeneTxnViewModal from "../../../components/BeneTransaction/BeneTxnViewModal";
import BeneTxnViewModalOwner from "../../../components/BeneTransaction/BeneTxnViewModalOwner";
import search_blue from "../../../assets/ViewAllTransactions/search_blue.png";
import search_white from "../../../assets/Approve transactions/search-white.png";
import { debounce } from "lodash";
import BeneTxnListView from "../../../components/BeneTransaction/BeneTxnListView";

const BeneTxnViewall = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const approvalStatus = location.state ? location.state.approvalStatus : "";
  const category = location.state ? location.state.category : "";
  const [initCategory, setInitCategory] = useState(category ? category : "");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("all");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(
    category ? category : ""
  );
  const [searchUserText, setSearchUserText] = useState("");
  const [showDataCountPopup, setShowDataCountPopup] = useState(false);
  const [createdOnFilter, setCreatedOnFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isPaymentMethod, setIsPaymentMethod] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [txnAmount, setTxnAmount] = useState("");
  const [txnAmountFilter, setTxnAmountFilter] = useState("");
  const [mainData, setMainData] = useState([]);
  // const [searchTxnAmount, setSearchTxnAmount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedBeneAccId, setSelectedBeneAccId] = useState("");
  const [selectedOwnerAccId, setSelectedOwnerAccId] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [beneAccountId, setBeneAccountId] = useState(null);
  const [accountName, setAccountName] = useState("");
  const [accountNameOwner, setAccountNameOwner] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownPaymentOpen, setDropdownPaymentOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(60);
  const [selectedValue, setSelectedValue] = useState(60);
  const [dropdownOptions, setDropdownOptions] = useState([60, 120, 180, 240]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [currentStatusDistribution, setCurrentStatusDistribution] =
    useState("all");
  const [isDistributionSelected, setIsDistributionSelected] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isViewModalOpenOwner, setIsViewModalOpenOwner] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDistributionPopoverOpen, setIsDistributionPopoverOpen] =
    useState(false);
  const [inputValue, setInputValue] = useState(1);
  const [accountNumber, setAccountNumber] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [IFSC, setIFSC] = useState("");
  const [bankLogo, setBankLogo] = useState("");

  const [txnPayLoad, setTxnPayLoad] = useState({
    bankAccountName: "",
    beneAccountId: "",
    bankAccountIFSC: "",
    bankAccountNo: "",
    categories: "",
    pan: "",
    ownerAccountId: "",
    amount: "",
    ownerbankAccountNo: "",
  });
  const dispatch = useDispatch();
  const {
    beneTxnViewAllAccData,
    toggle,
    storedPage,
    setIsApprovalStatusSelected,
  } = useSelector((state) => state.beneTxnViewAccData);

  useEffect(() => {
    const newTotalPages = Math.ceil(totalCount / pageSize);
    setTotalPages(newTotalPages);

    setCurrentPage((prevPage) => {
      if (newTotalPages === 0) {
        return 1;
      }
      if (prevPage > newTotalPages) {
        return newTotalPages;
      }
      return prevPage;
    });
  }, [totalCount, pageSize]);
  useEffect(() => {
    setDropdownOptions(
      [60, 120, 180, 240].filter((value) => value !== selectedValue)
    );
  }, [selectedValue]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(Number(storedPage));
    setAccumulatedData([]);
    toggleDropdown();
  };

  const lastElementRef = useRef();
  const filterRef = useRef(null);
  const viewModalRef = useRef(null);
  const settlementDropdownRef = useRef(null);
  const categoryDropdownRef = useRef(null);
  const paymentDropdownRef = useRef(null);

  const dateRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchBarRef = useRef(null);

  const handleFilterCategory = (selectedCategoryName) => {
    setCurrentCategory(selectedCategoryName);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
    setShowDatePicker(false);
  };
  const handleOpenViewModal = () => {
    setIsViewModalOpen(true);
  };
  const handleOpenViewModalOwner = () => {
    setIsViewModalOpenOwner(true);
  };

  const handleCloseViewModalOwner = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsViewModalOpenOwner(false);
  };
  const handleCloseViewModal = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsViewModalOpen(false);
  };

  const handleAccountSelection = (
    accountName,
    accountNumber,
    ifsc,
    beneAccountId
  ) => {
    setAccountName(accountName);
    setAccountNumber(accountNumber);
    setIFSC(ifsc);
    setBeneAccountId(beneAccountId);

    setTxnPayLoad({
      ...txnPayLoad,
      bankAccountName: accountName,
      bankAccountNo: accountNumber,
      bankAccountIFSC: ifsc,
      beneAccountId: beneAccountId,
    });
    handleCloseViewModal();
  };

  const handleAmountSelected = (amount) => {
    setTxnPayLoad({
      ...txnPayLoad,
      amount: txnAmountFilter,
    });
  };

  const handleAccountSelectionOwner = (
    accountName,
    accountNumber,
    ifsc,
    beneAccountId
  ) => {
    setTxnPayLoad({
      ...txnPayLoad,
      ownerAccountId: beneAccountId,
      ownerbankAccountNo: accountNumber,
    });
    handleCloseViewModalOwner();
  };

  // useEffect(() => {
  //   setCurrentStatus(approvalStatus);
  //   if (approvalStatus && approvalStatus !== "all") {
  //     setIsStatusSelected(true);
  //   } else {
  //     setIsStatusSelected(false);
  //   }
  //   dispatch(checkUsertoken());
  // }, [approvalStatus]);

  useEffect(() => {
    // const capitalizeFirstLetter = (string) => {
    //   return string.charAt(0).toUpperCase() + string.slice(1);
    // };
    // const capitalizeFirstLetter = (string) => {
    //   return string.charAt(0).toUpperCase() + string.slice(1);
    // };

    const handleAction = async () => {
      if (currentStatus === "require approval") {
        try {
          setLoading(true);
          dispatch(checkUsertoken());
          const responseApproval = await dispatch(
            BeneTxnRequireApprovalPage({
              page_no: currentPage,
              page_item_count: pageSize,
            })
          );

          const newApprovalData = responseApproval?.payload?.transactions || [];
          setMainData(responseApproval?.payload);
          setAccumulatedData(newApprovalData);
          setTotalCount(responseApproval?.payload?.total_count || 0);
          setLoading(false);
          setShowDataCountPopup(true);
        } catch (error) {
          setAccumulatedData([]);
          setMainData([]);
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          let queryParams = {
            page_no: currentPage,
            page_item_count: pageSize,
          };

          const addParamsBasedOnSelection = () => {
            if (
              currentStatusDistribution &&
              currentStatusDistribution !== "all"
            ) {
              queryParams.distribution_lifecycle_status =
                currentStatusDistribution;
            }

            if (currentStatus && currentStatus !== "all") {
              queryParams.lifecycle_status = currentStatus;
            }

            if (currentCategory !== "") {
              queryParams.category = currentCategory;
            }
            if (startDateFilter !== "" && endDateFilter !== "") {
              queryParams.start_date = startDateFilter;
              queryParams.end_date = endDateFilter;
            }
            if (isPaymentMethod && isPaymentMethod !== "All") {
              queryParams.payment_method = isPaymentMethod;
            }

            if (txnAmount !== "") {
              queryParams.amount = txnAmount;
            }
            if (txnAmountFilter !== "") {
              queryParams.amount = txnAmountFilter;
            }
            if (selectedBeneAccId !== "") {
              queryParams.beneficiary_account_id = selectedBeneAccId;
            }
            if (selectedOwnerAccId !== "") {
              queryParams.owner_account_id = selectedOwnerAccId;
            }
          };

          addParamsBasedOnSelection();

          dispatch(checkUsertoken());
          const response = await dispatch(beneTxnViewAllAcc(queryParams));

          const newData = response?.payload?.transactions || [];
          setMainData(response?.payload);
          setAccumulatedData(newData);
          setTotalCount(response?.payload?.total_count || 0);
          setLoading(false);
          setShowDataCountPopup(true);
        } catch (error) {
          setAccumulatedData([]);
          setMainData([]);
          setLoading(false);
        }
      }
    };
    handleAction();
  }, [
    dispatch,
    currentPage,
    currentStatus,
    currentStatusDistribution,
    currentCategory,
    startDateFilter,
    endDateFilter,
    pageSize,
    isPaymentMethod,
    txnAmount,
    txnAmountFilter,
    selectedBeneAccId,
    selectedOwnerAccId,
  ]);

  useEffect(() => {
    setInputValue(String(currentPage).padStart(2, "0"));
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(1);
    setInputValue("1");
  }, []);

  const getAccStatus = (item, ele) => {
    if (ele === "distribution") {
      setCurrentStatusDistribution(item);
      setIsDistributionSelected(true);
      setIsStatusSelected(false);
    } else if (ele === "status") {
      setCurrentStatus(item);
      setIsDistributionSelected(false);
      setIsStatusSelected(true);
    }
  };

  const handleFilterSticker = () => {
    setCurrentCategory("");
    setInitCategory("");
    navigate(`/transaction/beneficiary_transaction/view_all`, {
      state: { approvalStatus: "all", category: null },
    });
  };
  useEffect(() => {
    updateIsFilterApplied();
  }, [
    currentCategory,
    startDateFilter,
    endDateFilter,
    txnAmountFilter,
    isPaymentMethod,
    selectedBeneAccId,
    selectedOwnerAccId,
  ]);

  const updateIsFilterApplied = () => {
    setIsFilterApplied(
      !!currentCategory ||
        !!(startDateFilter && endDateFilter) ||
        !!isPaymentMethod ||
        !!selectedBeneAccId ||
        txnAmountFilter ||
        selectedOwnerAccId
    );
  };
  const getArrowIcon = (isSidebarOpen, isFilterApplied) => {
    if (isSidebarOpen) {
      return isFilterApplied ? ArrowUp4 : arrowup;
    } else {
      return isFilterApplied ? ArrowDown8 : arrowdown;
    }
  };
  const handleFilterDateRange = (startDate, endDate) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };
  const handlePaymentMethod = (selectedSettlement) => {
    setIsPaymentMethod(selectedSettlement);
  };

  const handleFilterAmountMethod = () => {
    setTxnAmountFilter("");
    setTxnPayLoad({
      ...txnPayLoad,
      amount: "",
    });
  };

  const handleFilterDateSticker = () => {
    setStartDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    setEndDate("");
  };

  const handleFilterBeneAccSticker = () => {
    setBeneAccountId(null);
    setSelectedBeneAccId("");
    setAccountName("");
  };
  const handleFilterPaymentSticker = () => {
    setIsPaymentMethod("");
    setPaymentMethod("");
  };

  const handleFilterPaymentStickerOwner = () => {
    setSelectedOwnerAccId("");
    setTxnPayLoad({
      ...txnPayLoad,
      ownerbankAccountNo: "",
      ownerAccountId: "",
    });
  };
  const handleSearch = (text) => {
    if (text) {
      const sanitizedText = text.replace(/,/g, "");
      const amount = parseFloat(sanitizedText);
      if (!isNaN(amount) && amount >= 0) {
        setTxnAmount(sanitizedText);
      } else if (sanitizedText === "") {
        setTxnAmount("");
      }
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();

    setTxnAmount(e.target.value);
  };
  const handleTxnAmountSearch = (selectedTxnId) => {
    setTxnAmount(selectedTxnId);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isViewModalOpen || isViewModalOpenOwner) {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
          return;
        }
        return;
      }

      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !(
          settlementDropdownRef.current &&
          settlementDropdownRef.current.contains(event.target)
        ) &&
        !(
          categoryDropdownRef.current &&
          categoryDropdownRef.current.contains(event.target)
        ) &&
        !(dateRef.current && dateRef.current.contains(event.target)) &&
        !(
          paymentDropdownRef.current &&
          paymentDropdownRef.current.contains(event.target)
        )
      ) {
        setIsSidebarOpen(false);
        setIsDropdownOpen(false);
        setDropdownOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowSearchBar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isViewModalOpen, isViewModalOpenOwner]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };
  const marginTop = isFilterApplied
    ? "17.6rem"
    : isPopoverOpen || isDistributionPopoverOpen
    ? "13rem"
    : showSearchBar
    ? "18rem"
    : // : toggle
      // ? "4rem"
      "14rem";

  const getIcon = () => {
    if (showSearchBar) {
      return txnAmount !== "" ? ArrowUp4 : arrowup;
    } else {
      return txnAmount !== "" ? ArrowDown8 : arrowdown;
    }
  };
  const handlePageChange = useCallback(
    debounce((page) => {
      const pageNumber = Number(page);
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      } else if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(1);
      }
    }, 300),

    [totalPages]
  );

  const debouncedHandlePageChange = debounce((value) => {
    handlePageChange(value);
  }, 1000);

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (value === "") {
        setInputValue("");
        setCurrentPage(1);
      } else if (numericValue > totalPages) {
        setInputValue(
          String(totalPages).padStart(String(totalPages).length, "0")
        );
        setCurrentPage(totalPages);
      } else if (numericValue < 1) {
        setInputValue(1);
        setCurrentPage(1);
      } else {
        setInputValue(value);
        debouncedHandlePageChange(value);
      }
    }
  };

  return (
    <>
      <div
        id="beneScrollContainer"
        className={`bg-custom-appBack flex flex-col h-screen`}
      >
        {isPopoverOpen && <div className="acc_blackTxn_div"></div>}
        {isSidebarOpen && <div className="acc_blackTxn_div"></div>}
        {isDistributionPopoverOpen && <div className="acc_blackTxn_div"></div>}

        {showDataCountPopup && accumulatedData?.length > 0 && (
          <div className="fixed bottom-8 w-full flex justify-center items-center z-[99999]">
            <DataCountPopup data={mainData} pageSize={currentPage} />
          </div>
        )}
        {isViewModalOpen && (
          <div
            style={{ zIndex: "2147483647" }}
            className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
          >
            <BeneTxnViewModal
              setIsSidebarOpen={setIsSidebarOpen}
              onClosed={handleCloseViewModal}
              onAccountSelect={handleAccountSelection}
              setTxnPayLoad={setTxnPayLoad}
              txnPayLoad={txnPayLoad}
              accountNumber={accountNumber}
              IFSC={IFSC}
              bankLogo={bankLogo}
            />
          </div>
        )}

        {isViewModalOpenOwner && (
          <div
            style={{ zIndex: "2147483647" }}
            className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
          >
            <BeneTxnViewModalOwner
              setIsSidebarOpen={setIsSidebarOpen}
              onClosed={handleCloseViewModalOwner}
              onAccountSelect={handleAccountSelectionOwner}
              setTxnPayLoad={setTxnPayLoad}
              txnPayLoad={txnPayLoad}
              accountNumber={accountNumber}
              IFSC={IFSC}
              bankLogo={bankLogo}
            />
          </div>
        )}

        <div
          className="bg-custom-appBack flex flex-col h-screen"
          id=" benetxnmain "
          style={{
            overflow: isSidebarOpen && "hidden",
            // opacity: isViewModalOpen || isViewModalOpenOwner ? 0.2 : 1,
          }}
        >
          <div className="beneTxnViewAllNavbar">
            <div className="beneTxnViewAllContent"></div>
            <div
              className="beneTxnViewAllSec1"
              style={{
                height: showSearchBar ? "292px" : "220px",
                transition: showSearchBar
                  ? "all 0.2s ease-in"
                  : "all 0.2s ease-in",
              }}
            >
              <div className="beneTxnViewAllSec2">
                <div
                  className="beneTxnViewAll_middleBar"
                  style={{
                    borderBottom: showSearchBar ? "1px solid #1D3A6D" : "",
                    // height: showSearchBar ? "124px" : "102px",
                  }}
                >
                  <div
                    className="beneTxnViewAllWholeSection"
                    // style={{
                    //   borderBottom: showSearchBar ? "1px solid #1D3A6D" : "",
                    // }}
                  >
                    <div className="beneTxnViewAllParentdiv">
                      <div className="beneTxnViewAllchild1">
                        <BeneTxnStatusPopover
                          data={accumulatedData}
                          getAccStatus={getAccStatus}
                          selectedStatus={currentStatus}
                          setPage={setCurrentPage}
                          setIsApprovalStatusSelected={
                            setIsApprovalStatusSelected
                          }
                          isDistributionSelected={isDistributionSelected}
                          setIsDistributionSelected={setIsDistributionSelected}
                          isStatusSelected={isStatusSelected}
                          setIsStatusSelected={setIsStatusSelected}
                          setIsPopoverOpen={setIsPopoverOpen}
                          isPopoverOpen={isPopoverOpen}
                        />
                      </div>
                    </div>
                    <div className="beneTxnViewallAmountSection">
                      <div className="beneTxnViewallTotalAmount">
                        <DistributionStatusPopover
                          data={accumulatedData}
                          getAccStatus={getAccStatus}
                          selectedStatus={currentStatusDistribution}
                          setPage={setCurrentPage}
                          isDistributionSelected={isDistributionSelected}
                          setIsDistributionSelected={setIsDistributionSelected}
                          isStatusSelected={isStatusSelected}
                          setIsStatusSelected={setIsStatusSelected}
                          setIsDistributionPopoverOpen={
                            setIsDistributionPopoverOpen
                          }
                          isDistributionPopoverOpen={isDistributionPopoverOpen}
                        />
                      </div>
                    </div>
                  </div>

                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title="Search"
                  >
                    <div
                      ref={searchBarRef}
                      className={`beneTxn_viewAll_search_div ${
                        txnAmount !== "" ? "searched" : ""
                      } hover:bg-[#506994]`}
                      onClick={() => {
                        setShowSearchBar((prev) => !prev);
                        setIsSidebarOpen(false);
                      }}
                    >
                      <img
                        className="bene_txn_search-image"
                        src={txnAmount ? search_blue : search_white}
                        alt=""
                      />
                      <img
                        src={getIcon()}
                        alt=""
                        className="w-[13px] h-[13px]"
                      />

                      {showSearchBar && (
                        <>
                          <TxnSearchBar
                            handleSearch={handleSearch}
                            showSearchBar={showSearchBar}
                            setShowSearchBar={setShowSearchBar}
                            setCurrentPage={setCurrentPage}
                            // setSearchTxnAmount={setSearchTxnAmount}
                            txnAmount={txnAmount}
                            setTxnAmount={setTxnAmount}
                            handlePaste={handlePaste}
                            toggleShowSearchBar={() => {
                              setShowSearchBar(!showSearchBar);
                            }}
                            bgColor={"bg-custom-card"}
                            bgHover={"hover:bg-custom-cardHover"}
                          />
                        </>
                      )}
                    </div>
                  </Tooltip>
                  <div
                    ref={filterRef}
                    className={`beneTxn_viewall_filter_div ${
                      currentCategory !== "" ||
                      startDateFilter ||
                      endDateFilter ||
                      isPaymentMethod ||
                      selectedOwnerAccId ||
                      txnAmountFilter ||
                      selectedBeneAccId
                        ? "filtered"
                        : ""
                    }`}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Filters"
                    >
                      <div
                        style={{
                          pointerEvents:
                            isViewModalOpen || isViewModalOpenOwner
                              ? "none"
                              : "",
                        }}
                        className="beneTxnViewAll_filter_container "
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSidebar();
                          setShowSearchBar(false);
                        }}
                      >
                        <Box className="beneTxnViewAll_filter_box">
                          <img
                            src={isFilterApplied ? FilterBlue : Filter}
                            alt=""
                            className="bene-txn-filter-image"
                          />
                        </Box>
                        <Box className="beneTxnViewAll_filter_box">
                          {" "}
                          <img
                            src={getArrowIcon(isSidebarOpen, isFilterApplied)}
                            alt=""
                            className="filter-image"
                          />
                        </Box>
                      </div>
                    </Tooltip>
                    <BeneTxnFilterSidebar
                      isOpen={isSidebarOpen}
                      onClose={toggleSidebar}
                      sendCategory={handleFilterCategory}
                      sendDateRange={handleFilterDateRange}
                      setPage={setCurrentPage}
                      setIsSidebarOpen={setIsSidebarOpen}
                      initCategory={initCategory}
                      setInitCategory={setInitCategory}
                      isPaymentMethod={isPaymentMethod}
                      setEndDateFilter={setEndDateFilter}
                      setStartDateFilter={setStartDateFilter}
                      sendPaymentMethod={handlePaymentMethod}
                      setTxnPayLoad={setTxnPayLoad}
                      handleAmountSelected={handleAmountSelected}
                      txnPayLoad={txnPayLoad}
                      isSidebarOpen={isSidebarOpen}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                      selectedBeneAccId={selectedBeneAccId}
                      setSelectedBeneAccId={setSelectedBeneAccId}
                      beneAccountId={beneAccountId}
                      setBeneAccountId={setBeneAccountId}
                      accountName={accountName}
                      setAccountName={setAccountName}
                      showDatePicker={showDatePicker}
                      setShowDatePicker={setShowDatePicker}
                      isFilterApplied={isFilterApplied}
                      setIsFilterApplied={setIsFilterApplied}
                      settlementDropdownRef={settlementDropdownRef}
                      paymentDropdownRef={paymentDropdownRef}
                      categoryDropdownRef={categoryDropdownRef}
                      dateRef={dateRef}
                      dropdownOpen={dropdownOpen}
                      setIsDropdownOpen={setIsDropdownOpen}
                      isDropdownOpen={isDropdownOpen}
                      setDropdownOpen={setDropdownOpen}
                      isDropdownPaymentOpen={isDropdownPaymentOpen}
                      setDropdownPaymentOpen={setDropdownPaymentOpen}
                      setTxnAmountFilter={setTxnAmountFilter}
                      txnAmountFilter={txnAmountFilter}
                      onClosed={handleCloseViewModal}
                      onAccountSelect={handleAccountSelection}
                      isViewModalOpen={isViewModalOpen}
                      handleOpenViewModal={handleOpenViewModal}
                      viewModalRef={viewModalRef}
                      handleOpenViewModalOwner={handleOpenViewModalOwner}
                      selectedOwnerAccId={selectedOwnerAccId}
                      setSelectedOwnerAccId={setSelectedOwnerAccId}
                    />
                  </div>

                  <div className="bene-txn-viewall-page-select-div">
                    <div
                      style={{
                        height: "34px",
                        width: "70px",
                        padding: "7px 0px 0px 8px",
                        color: "#DADADA",
                        fontSize: "12px",
                      }}
                    >
                      Per page :
                    </div>

                    <div
                      ref={dropdownRef}
                      onClick={toggleDropdown}
                      className="w-[60px] bene_viewall_data_dropdown"
                      style={{
                        backgroundColor: isOpen ? "#667CA3" : "#F9F9F9",
                        height: isOpen ? "114px" : "34px",
                      }}
                    >
                      <div
                        style={{
                          height: "17px",
                          width: "47px",
                          display: "flex",
                          backgroundColor: isOpen ? "#1D3A6D" : "#F9F9F9",
                          borderRadius: isOpen ? "2px" : "",
                          gap: "7px",
                          marginLeft: "8px",
                        }}
                      >
                        <span
                          className="text-xs text-center ml-[4px]"
                          style={{ color: isOpen ? "#FFFFFF" : "#314D7E" }}
                        >
                          {selectedValue}
                        </span>
                        <img
                          src={isOpen ? allChecker : ArrowDown8}
                          alt={isOpen ? "Checkmark" : "Arrow Down"}
                          className="w-[10px] h-[11px] mt-[3px]"
                        />
                      </div>
                      {isOpen && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: "#667CA3",
                            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                            borderRadius: "5px",
                            marginTop: "20px",
                            zIndex: 1,
                          }}
                        >
                          {dropdownOptions.map((value) => (
                            <div
                              key={value}
                              onClick={() => handleSelect(value)}
                              className="bene_txn_dropdown_options"
                            >
                              <span>{value}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bene-txn-viewall-pagination-containers">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Prev"
                    >
                      <div
                        onClick={prevPage}
                        disabled={currentPage === 1}
                        style={{
                          height: "34px",
                          width: "32px",
                          padding: "10px 0px 0px 8px",
                          cursor: currentPage === 1 ? "default" : "pointer",
                        }}
                      >
                        <img
                          src={ArrowLeft}
                          alt=""
                          style={{
                            opacity: currentPage === 1 ? 0.5 : 1,
                            cursor: currentPage === 1 ? "default" : "pointer",
                          }}
                          className="w-[11px] h-[14px]"
                        />
                      </div>
                    </Tooltip>
                    <input
                      type="text"
                      value={inputValue}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      className="bene_txn_viewall_pagination_input"
                      maxLength={String(totalPages).length}
                    />
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Next "
                    >
                      <div
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                        style={{
                          height: "34px",
                          width: "74px",
                          padding: "8px 0px 0px 10px",
                          display: "flex",
                          gap: "5px",
                          cursor:
                            currentPage === totalPages ? "default" : "pointer",
                        }}
                      >
                        <span
                          className="text-xs text-center text-[#DADADA]"
                          id="id-firstLetter"
                        >
                          of {totalPages}
                        </span>
                        <img
                          src={ArrowRight}
                          style={{
                            opacity: currentPage >= totalPages ? 0.5 : 1,
                            cursor:
                              currentPage >= totalPages ? "default" : "pointer",
                          }}
                          alt=""
                          className="w-[11px] h-[14px] pt-[1px]"
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <div className="bene_txn_viewall_toggle_containers">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Grid"
                    >
                      <Box
                        onClick={() => {
                          // setSearchUserText("");
                          dispatch(setToggle(false));
                          setCurrentPage(1);
                          // setCurrentPageText("01");
                        }}
                        sx={{
                          backgroundColor: toggle ? "" : "#fff",
                        }}
                        className={`toggle-box`}
                      >
                        <img
                          src={toggle ? GridWhite : GridBlue}
                          alt=""
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="List"
                    >
                      <Box
                        onClick={() => {
                          setSearchUserText("");
                          dispatch(setToggle(true));
                          // toast.info("Currently in development", toast_position);
                          setCurrentPage(1);
                          // setCurrentPageText("01");
                        }}
                        sx={{
                          backgroundColor: toggle ? "#fff" : "",
                        }}
                        className={`toggle-box `}
                      >
                        <img
                          src={toggle ? ListBlue : ListWhite}
                          alt=""
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(currentCategory !== "" ||
            startDateFilter ||
            endDateFilter ||
            isPaymentMethod ||
            txnAmountFilter ||
            selectedOwnerAccId ||
            selectedBeneAccId) && (
            <div className="bene_txn_filterdropdown-container-div">
              <div className="bene_txn_filterdropdown">
                <div
                  style={{
                    display: "flex",
                    // width: "45%",
                  }}
                >
                  <div className="bene_txn_viewall_filter_div_left">
                    <p className="pr-2 w-[87px]">Filtered by : </p>
                    {currentCategory && (
                      <div className="bene_txn_viewall_filter_body px-2 mr-[5px]">
                        <div className="bene_txn_viewall_filter_img_div">
                          <img src={rentalImg} alt={currentCategory} />
                        </div>
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={currentCategory}
                        >
                          <p className="px-2 text-xs truncate h-[19px]">
                            {currentCategory}
                          </p>
                        </Tooltip>
                        <div
                          className="bene_txn_viewall_close_btn"
                          onClick={handleFilterSticker}
                        >
                          {" "}
                          <img
                            src={closeBtnImg}
                            alt="close-btn"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    )}
                    {startDateFilter && endDateFilter && (
                      <div className="bene_txn_viewall_filter_body px-2 w-[295px] mr-[10px]">
                        <div className="px-2">
                          {formatDate(new Date(startDateFilter))}
                        </div>
                        <img
                          src={arrowForward}
                          className="bene_acc_viewall_calendar_icon"
                        />
                        <div className="px-2">
                          {formatDate(new Date(endDateFilter))}
                        </div>
                        <div
                          className="bene_txn_viewall_close_btn"
                          onClick={() => {
                            handleFilterDateSticker();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <img
                            src={closeBtnImg}
                            alt="close-btn"
                            className="w-[12px] h-[12px]"
                          />
                        </div>
                      </div>
                    )}

                    {selectedBeneAccId && (
                      <div className="bene_txn_viewall_filter_body px-2 mr-[5px]">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={accountName?.toLowerCase()}
                        >
                          <p
                            className="px-2 text-xs truncate"
                            style={{
                              flex: "1",
                              width: "112px",
                              color: "#FFFFFF",
                              letterSpacing: " 0px",
                              textTransform: "capitalize",
                              opacity: "1",
                            }}
                          >
                            {accountName?.toLowerCase()}
                          </p>
                        </Tooltip>
                        <div
                          className="bene_txn_viewall_close_btn"
                          onClick={() => {
                            handleFilterBeneAccSticker();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <img
                            src={closeBtnImg}
                            alt="close-btn"
                            className="w-[12px] h-[12px]"
                          />
                        </div>
                      </div>
                    )}
                    {isPaymentMethod && (
                      <div className="bene_txn_viewall_filter_body px-2">
                        <p className="px-2">{isPaymentMethod}</p>
                        <div
                          className="bene_txn_viewall_close_btn"
                          onClick={() => {
                            handleFilterPaymentSticker();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <img
                            src={closeBtnImg}
                            alt="close-btn"
                            className="w-[12px] h-[12px]"
                          />
                        </div>
                      </div>
                    )}
                    {selectedOwnerAccId && (
                      <div className="bene_txn_viewall_filter_body px-2">
                        <p className="px-2">
                          {formatAccountNumber(selectedOwnerAccId)}
                        </p>
                        <div
                          className="bene_txn_viewall_close_btn"
                          onClick={() => {
                            handleFilterPaymentStickerOwner();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <img
                            src={closeBtnImg}
                            alt="close-btn"
                            className="w-[12px] h-[12px]"
                          />
                        </div>
                      </div>
                    )}
                    {txnAmountFilter && (
                      <div className="bene_txn_viewall_filter_body px-2">
                        <p className="px-2">{"₹ " + txnAmountFilter}</p>
                        <div
                          className="bene_txn_viewall_close_btn"
                          onClick={() => {
                            handleFilterAmountMethod();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <img
                            src={closeBtnImg}
                            alt="close-btn"
                            className="w-[12px] h-[12px]"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="beneTxnViewAll_middle_section_container"
            style={{ marginTop }}
          ></div>
          <div
            id="txnViewallScrollContainer"
            style={{
              opacity:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "0.8"
                  : "1",
              backgroundColor:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "#000"
                  : "",
              overflowY:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "hidden"
                  : "auto",
              height:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "100vh"
                  : "100%",
            }}
          >
            {!toggle ? (
              loading ? (
                <div className="loader-container m-auto justify-center items-center flex">
                  <img src={dataLoading} alt="" />
                </div>
              ) : accumulatedData?.length > 0 ? (
                <div
                  className="flex flex-col items-center p-1 w-full h-full mt-4"
                  style={{
                    opacity:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? "0.3"
                        : "1",
                    background:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? "#000"
                        : "",
                    pointerEvents:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen ||
                      isViewModalOpen ||
                      isViewModalOpenOwner
                        ? "none"
                        : "",
                  }}
                >
                  <BeneTxnViewAllCard
                    data={accumulatedData}
                    lastElementRef={lastElementRef}
                    isSidebarOpen={isSidebarOpen}
                    numberOfPages={totalPages}
                    isCardHovered={isCardHovered}
                    setIsCardHovered={setIsCardHovered}
                    isPopoverOpen={isPopoverOpen}
                    isDistributionPopoverOpen={isDistributionPopoverOpen}
                  />
                </div>
              ) : setTxnAmount && accumulatedData?.length === 0 ? (
                <div
                  className="flex flex-col items-center justify-center m-auto"
                  style={{
                    opacity:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? 0.3
                        : 1,
                  }}
                >
                  <img
                    src={noaccount}
                    alt="No accounts found"
                    className="bene-txn-no-acc-image"
                  />
                  <p className="no-beneaccount-text" style={{ width: "314px" }}>
                    No transactions are found for the selected filter.
                  </p>
                </div>
              ) : null
            ) : (
              <div
                className={
                  isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                    ? `h-full bg-black opacity-40`
                    : "w-full h-full"
                }
              >
                {/* <div
                style={{ marginTop: isFilterApplied ? "" : "10rem" }}
                className="listview-container w-full overflow-y-auto h-4/6 ownerviewallscroll"
              > */}
                {loading ? (
                  <div className="loader-container m-auto justify-center items-center flex w-full h-full">
                    <img src={dataLoading} alt="" />
                  </div>
                ) : accumulatedData?.length > 0 ? (
                  // <div
                  //   className={`p-1 mx-[10px] w-full ${
                  //     isSidebarOpen && "bg-black opacity-10 z-[-9999]"
                  //   }`}
                  // >
                  <div className="flex flex-col items-center p-1 w-full h-full">
                    <BeneTxnListView
                      // handleRefresh={handleRefresh}
                      data={accumulatedData}
                      isSidebarOpen={isSidebarOpen}
                      page={currentPage}
                      isPopoverOpen={isPopoverOpen}
                      isDistributionPopoverOpen={isDistributionPopoverOpen}
                      // refreshAllData={refreshAllData}
                      // refreshType={refreshType}
                      // setRefreshtype={setRefreshtype}
                      // setFailedRefreshId={setFailedRefreshId}
                      // failedRefreshId={failedRefreshId}
                      // successRefreshId={successRefreshId}
                      // setSuccessRefreshId={setSuccessRefreshId}
                    />
                  </div>
                ) : searchUserText && accumulatedData?.length === 0 ? (
                  <div
                    className={
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? `h-full bg-black opacity-40 flex flex-col items-center justify-center m-auto`
                        : "flex flex-col items-center justify-center m-auto"
                    }
                  >
                    <img
                      src={noaccount}
                      alt="No accounts found"
                      className={
                        isSidebarOpen ? "no-acc-image-opacity" : "no-acc-image"
                      }
                    />
                    <p
                      className={
                        isSidebarOpen
                          ? "no-owneraccount-text-opacity"
                          : "no-owneraccount-text"
                      }
                    >
                      No transactions are found.
                    </p>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center justify-center m-auto h-full"
                    style={{
                      opacity:
                        isSidebarOpen ||
                        isPopoverOpen ||
                        isDistributionPopoverOpen
                          ? 0.4
                          : 1,
                    }}
                  >
                    <img
                      src={noaccount}
                      alt="No accounts found"
                      className="bene-txn-no-acc-image"
                    />
                    <p
                      className="bene-txn-no-beneaccount-text"
                      style={{ width: "314px" }}
                    >
                      No transactions are found for the selected filter.
                    </p>
                  </div>
                )}
                {/* </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BeneTxnViewall;
