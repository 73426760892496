import React, { useEffect, useState } from "react";
import "./RequireApproval.css";
import aprovaldenied from "../../../../assets/Beneficiary Icons/reddecline.png";
import aprovalaccept from "../../../../assets/Beneficiary Icons/WhiteApprove.png";
import xpentraloader from "../../../../assets/Beneficiary Icons/lodemore.gif";
import { ConvertBefiDate } from "../../../../utils/ConvertBefiDate";
import { beneRequiredApproval } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { beneWaitingApproved } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useDispatch, useSelector } from "react-redux";
import DeclinedPopup from "./DeclinedPopup";
import InfiniteScroll from "react-infinite-scroll-component";
import NoAccountFound from "../../../../assets/Beneficiary Icons/no_account_approved.png";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import { Bounce, toast } from "react-toastify";
import { tooltipClasses, Tooltip } from "@mui/material";
import SkipVerification from "../../../../assets/Approve transactions/DeclinedTxn_red.png";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import RejectingTransactionPopup from "./RejectingTransactionPopup/RejectingTransactionPopup";
import arrowleft from "../../../../assets/OwnerAccount/arrowleft.png";
import { useNavigate } from "react-router-dom";

const RequireApproval = () => {
  const dispatch = useDispatch();
  const [allAccounts, setAllAccounts] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [loading, setLoading] = useState({});
  const [BeneWaitingStatus, SetBeneWaitingStatus] = useState(true);
  const [ErrorMessage, SetErrorMessage] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [allBeneTxnAccountsLoading, setAllBeneTxnAccountsLoading] =
    useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);

  const navigate = useNavigate();

  const updateAllAccounts = (updatedAccounts) => {
    setAllAccounts(updatedAccounts);
  };

  let approvedpadCount;
  let deniedCount = 0;

  const { beneRequiredApprovalData } = useSelector(
    (state) => state.beneAllVerification
  );

  const { beneWaitingApprovalData } = useSelector((state) => {
    return state.beneAllVerification;
  });

  useEffect(() => {
    if (currentPage === 1) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());
    dispatch(
      beneRequiredApproval({
        page_no: currentPage,
      })
    )
      .then((response) => {
        setAllAccounts((prevAccounts) => {
          const newData =
            (response?.payload?.beneficiary_accounts &&
              response?.payload?.beneficiary_accounts?.map((account) => ({
                ...account,
                loading: false,
                error: "false",
                message: "",
              }))) ||
            [];

          return [...prevAccounts, ...newData];
        });
        setAllBeneTxnAccountsLoading(false);
      })
      .catch(() => {
        setAllBeneTxnAccountsLoading(false);
      });
  }, [dispatch, currentPage]);
  const handleOpenPopup = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setRejectTxnPopup(false);
  };

  const handleApprovalToastSuccess = () => {
    setApprovedCount((prevCount) => prevCount + 1);
    toast.success("Account approved successfully!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleApproval = async (id) => {
    setLoading((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    dispatch(checkUsertoken());
    const data = await dispatch(beneWaitingApproved(id));
    let errorMessage = "";
    if (data) {
      SetBeneWaitingStatus(data?.payload?.status);
      const errors = data?.payload?.errors;
      if (errors?.beneficiary_account?.[0]) {
        SetErrorMessage(errors.beneficiary_account[0]);
        errorMessage = errors.beneficiary_account[0];
      } else if (errors?.bank_account_number?.[0]) {
        SetErrorMessage(errors.bank_account_number[0]);
        errorMessage = errors.bank_account_number[0];
      } else {
        SetErrorMessage("An unknown error occurred.");
        errorMessage = "An unknown error occurred.";
      }
    }

    if (data) {
      const updatedData = allAccounts.map((item) => {
        if (item.id === id) {
          let lifecycleStatus = "";

          if (data.payload.status === true) {
            lifecycleStatus = "Approved";
          }
          if (data.payload.status === false) {
            lifecycleStatus = "error";
          }

          let message = "";
          if (data.payload.status === false) {
            const errors = data?.payload?.errors;
            if (errors?.beneficiary_account?.[0]) {
              message = errors.beneficiary_account[0];
            } else if (errors?.bank_account_number?.[0]) {
              message = errors.bank_account_number[0];
            }
          }

          return {
            ...item,
            approval_status:
              item.approval_status === "pending" ? lifecycleStatus : "pending",
            error:
              item.error === "false" && data?.payload.status === false
                ? "true"
                : "false",
            message:
              item.message === "" && data.payload.status === false
                ? message
                : "",
          };
        }

        return item;
      });

      setAllAccounts(updatedData);

      if (data?.payload.status === false) {
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    setTimeout(() => {
      setLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }, 2000);

    setTimeout(() => {
      if (data.payload.status === true) {
        handleApprovalToastSuccess();
      }
    }, 2000);
  };

  const AllAprovalAcountCount =
    beneRequiredApprovalData?.summary?.pending_accounts_count ?? "00";

  allAccounts?.forEach((account) => {
    if (account.approval_status === "rejected" && account.error !== "true") {
      deniedCount++;
    }
  });

  approvedpadCount = approvedCount.toString().padStart(2, "0");
  deniedCount = deniedCount.toString().padStart(2, "0");

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <DeclinedPopup
            onClose={handleClosePopup}
            accountId={deniedId}
            allAccounts={allAccounts}
            updateAllAccounts={updateAllAccounts}
            status={"Benificiary"}
            showRejectTnxPopup={showRejectTnxPopup}
            setRejectTxnPopup={setRejectTxnPopup}
          />
        </div>
      )}
      <div id="webviewd">
        <div id="RequireApprovalPage">
          <div className="ApprovalNavbar">
            <div className="Approvalsec1">
              <div className="Approvalsec2">
                <div className="approvalAcountTopbar">
                  <div
                    className="firstheaderApproval"
                    style={{ paddingLeft: "30px" }}
                  >
                    <div className="approvalParentdiv">
                      <p className="approvalchild1">
                        Σ {AllAprovalAcountCount}
                      </p>
                      <p className="approvalchild2">Total accounts</p>
                    </div>
                  </div>
                  <div className="SecondheaderApproval">
                    <div className="navbarleft">
                      <p className="approvedtag1">{approvedpadCount}</p>
                      <p className="approvedtag2"> Approved</p>
                    </div>
                    <div className="navbarright">
                      <p className="approvedtag3">{deniedCount}</p>
                      <p className="approvedtag4">Rejected</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="viewAllScrollContainerBar">
            {allBeneTxnAccountsLoading ? (
              <div className="loader-container m-auto justify-center items-center flex mt-20">
                <img src={dataLoading} alt="" />
              </div>
            ) : beneRequiredApprovalData?.summary?.pending_accounts_count ===
              undefined ? (
              <div className="BeneAccount_NoAccountFound">
                <div className="BeneAccount_RightTextContentNoAccount">
                  <img src={NoAccountFound} alt="" />
                </div>
                <div className="BeneAccount_LeftTextContentNoAccount">
                  <span>No pending accounts found.</span>
                  <p>There are no more accounts awaiting your approval.</p>
                </div>
              </div>
            ) : allAccounts?.length > 0 ? (
              <div className="scroll flex flex-col items-center justify-center w-full">
                {showPopup && (
                  <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom-backdrop bg-opacity-80">
                    <DeclinedPopup
                      onClose={handleClosePopup}
                      accountId={deniedId}
                      allAccounts={allAccounts}
                      updateAllAccounts={updateAllAccounts}
                      status={"Benificiary"}
                      showRejectTnxPopup={showRejectTnxPopup}
                      setRejectTxnPopup={setRejectTxnPopup}
                    />
                  </div>
                )}
                {/* {showRejectTnxPopup && (
                  <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
                    <RejectingTransactionPopup
                      accountId={deniedId}
                      handleClosePopup={handleClosePopup}
                    />
                  </div>
                )} */}
                <div
                  className={`scroll flex flex-col items-center justify-center w-full`}
                >
                  <InfiniteScroll
                    style={{ width: "inherit" }}
                    dataLength={allAccounts.length || []}
                    next={fetchMoreData}
                    hasMore={
                      currentPage <
                      Math.ceil(
                        beneRequiredApprovalData?.summary
                          ?.pending_accounts_count / pageSize
                      )
                    }
                    loader={
                      <div className="loader-container m-auto justify-center items-center flex">
                        <img src={dataLoading} alt="" />
                      </div>
                    }
                    scrollableTarget="viewAllScrollContainerBar"
                    scrollThreshold={0.8}
                  >
                    {allAccounts?.map((account, index) => (
                      <div className="approvedAcoutDetails" key={index}>
                        <div className="ApprovalAcdiv1">
                          <div className="Approvalsection1">
                            <div className="approvaltext1">
                              {account.name && account.name.length > 12 ? (
                                <p
                                  title={account.name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                >
                                  {account.name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </p>
                              ) : (
                                <p>
                                  {account.name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </p>
                              )}
                              <span>{account.contact_no}</span>
                            </div>
                            <div className="approvaltext2">
                              <p className="z1"> Name</p>
                              <span className="z2">Contact number</span>
                            </div>
                            <div className="approvaltext3">
                              {account.email_id &&
                              account.email_id.length > 12 ? (
                                <p
                                  title={account.email_id
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                >
                                  {account.email_id}
                                </p>
                              ) : (
                                <p>{account.email_id.toLowerCase()}</p>
                              )}
                              <span>Email ID</span>
                            </div>
                            <div className="approvaltext4">
                              {account.category &&
                              account.category.length > 12 ? (
                                <p title={account.category}>
                                  {account.category}
                                </p>
                              ) : (
                                <p>
                                  {account.category
                                    ? account.category
                                    : "Not Assigned"}
                                </p>
                              )}
                              <span>Assigned categories</span>
                            </div>
                          </div>
                        </div>

                        <div className="ApprovalAcdiv2 ">
                          <div className="Approvalsection2 p-[5px]  ">
                            <div className="approvalDiv1">
                              {account.bank_account_holder_full_name &&
                              account.bank_account_holder_full_name.length >
                                12 ? (
                                <p
                                  title={account.bank_account_holder_full_name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                >
                                  {account.bank_account_holder_full_name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </p>
                              ) : (
                                <p>
                                  {account.bank_account_holder_full_name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </p>
                              )}
                              <span className="approvalDiv1span mr-[5px]">
                                {account.pan_no}
                              </span>
                            </div>
                            <div className="approvalDiv2">
                              <span className="n1">Account holder name </span>
                              <span className="n2">PAN number</span>
                            </div>
                            <div className="approvalDiv3">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account.bank_acc_no}
                              >
                                <p>{account.bank_acc_no}</p>
                              </Tooltip>
                              <span
                                style={{
                                  marginRight: account.gstin_detail
                                    ? "62px"
                                    : "17px",
                                }}
                              >
                                {account.gstin_detail
                                  ? account.gstin_detail
                                  : "Not given"}{" "}
                              </span>
                            </div>
                            <div className="approvalDiv4">
                              <p>Account number</p>
                              <span>GSTIN</span>
                            </div>

                            <div className="approvalDiv5">
                              <div className="b">
                                <p>{account.ifsc_code}</p>
                                <span>IFSC code</span>
                              </div>
                              <div className="b1">
                                <img
                                  src={require("../../../../assets/IfscLogo/" +
                                    IfscCodeMapper(account.ifsc_code))}
                                  alt="bank"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="ApprovalAcdiv3">
                          <div className="Approvalsection3">
                            <div className="AprovalpageContainx1">
                              <p>{account.created_by_name}</p>
                              <span>{ConvertBefiDate(account.created_on)}</span>
                            </div>
                            <div className="AprovalpageContainx2">
                              <span className="b1">Requested by</span>
                              <span className="b2">Created on</span>
                            </div>

                            <div className="logo">
                              <div className="logo1">
                                {account.approval_status === "pending" &&
                                  !loading[account.id] && (
                                    <>
                                      <button
                                        className="Approvalleftside"
                                        onClick={() =>
                                          handleOpenPopup(account.id)
                                        }
                                      >
                                        <img src={aprovaldenied} alt="" />
                                        <p>Reject</p>
                                      </button>

                                      <div className="approvaldivider">
                                        <p className="divider-sec"></p>
                                      </div>
                                      <button
                                        className="Approvalrightside"
                                        onClick={() =>
                                          handleApproval(account.id)
                                        } // 1
                                      >
                                        <img src={aprovalaccept} alt="" />
                                        <p>Approve</p>
                                      </button>
                                    </>
                                  )}

                                {loading[account.id] && (
                                  <div className="w-8 h-7 m-auto my-3">
                                    <img src={xpentraloader} alt="Loading..." />
                                  </div>
                                )}

                                {account.approval_status === "Approved" &&
                                  account.error === "false" &&
                                  beneWaitingApprovalData &&
                                  !loading[account.id] && (
                                    <div
                                      className="Approvedpage"
                                      id={account.id}
                                    >
                                      <div className="Approvedpage1">
                                        <img src={aprovalaccept} alt="" />
                                      </div>
                                      <div className="Approvedpage2">
                                        <span>Approved!</span>
                                        <p>Account has been approved.</p>
                                      </div>
                                    </div>
                                  )}

                                {account.approval_status === "error" &&
                                  account.error === "true" &&
                                  !loading[account.id] && (
                                    <div className="Unknownpage">
                                      <div className="Unknownpage1">
                                        <img src={SkipVerification} alt="" />
                                      </div>
                                      <div className="Unknownpage2">
                                        <span>Failed to take action !</span>
                                        {account.message &&
                                        account.message.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.message}
                                          >
                                            <p className="w-[70px]  line-clamp-2   whitespace-wrap overflow-ellipsis   text-[14px] text-[#FCFCFC] opacity-100">
                                              {account?.message}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[70px] h-[50px]  overflow-hidden whitespace-wrap overflow-ellipsis  text-[14px]  text-[#FCFCFC] opacity-100">
                                            {account?.message}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                {account.approval_status === "rejected" &&
                                  account.error === "false" &&
                                  !loading[account.id] && (
                                    <div className="Declinedpage">
                                      <div className="Declinedpage1">
                                        <img src={aprovaldenied} alt="" />
                                      </div>
                                      <div className="Declinedpage2">
                                        <span>Rejected!</span>
                                        <p>Account has been Rejected.</p>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div id="mobileviewd">
        <div className="bene_approval_heder_containerm">
          <div className="allBeneapprovalheadertext">
            <div
              className="allBeneapprovalheaderarrow"
              onClick={() => navigate(-1)}
            >
              <img src={arrowleft} alt="" />
            </div>
            <div className="allBeneapprovalheadertextm">
              <div className="headingAccountDetail text-[#ffffff]">
                Beneficiary
              </div>
              <div className="headertextbottomv text-[#c9c9c9]">
                Accounts approval
              </div>
            </div>
          </div>
        </div>
        <div className="bene_approval_containerm">
          <div className="allBeneapprovaltext">
            <p>
              Total accounts :{" "}
              <span style={{ color: "#FAFAFA", fontWeight: "bold" }}>
                Σ {AllAprovalAcountCount}
              </span>
            </p>
          </div>
          <div className="SecondheaderApprovalm">
            <div className="">
              <p className="approvedtag2">
                {" "}
                Approved :{" "}
                <span style={{ color: "#3cb371", fontWeight: "bold" }}>
                  {approvedpadCount}
                </span>
              </p>
            </div>
            <div className="">
              <p className="approvedtag4 ml-4">
                Rejected :{" "}
                <span style={{ color: "#ff5860", fontWeight: "bold" }}>
                  {deniedCount}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div id="viewAllScrollContainerBar">
          {allBeneTxnAccountsLoading ? (
            <div className="loader-container m-auto justify-center items-center flex mt-20">
              <img src={dataLoading} alt="" />
            </div>
          ) : beneRequiredApprovalData?.summary?.pending_accounts_count ===
            undefined ? (
            <div className="BeneAccount_NoAccountFound">
              <div className="BeneAccount_LeftTextContentNoAccount">
                <span>No pending accounts found.</span>
                <p>There are no more accounts awaiting your approval.</p>
              </div>
              <div className="BeneAccount_RightTextContentNoAccount">
                <img src={NoAccountFound} alt="" />
              </div>
            </div>
          ) : allAccounts?.length > 0 ? (
            <div className="scroll flex flex-col items-center justify-center w-full">
              <div
                className={`scroll flex flex-col items-center justify-center w-full`}
              >
                <InfiniteScroll
                  style={{ width: "inherit" }}
                  dataLength={allAccounts.length || []}
                  next={fetchMoreData}
                  hasMore={
                    currentPage <
                    Math.ceil(
                      beneRequiredApprovalData?.summary
                        ?.pending_accounts_count / pageSize
                    )
                  }
                  loader={
                    <div className="loader-container m-auto justify-center items-center flex">
                      <img src={dataLoading} alt="" />
                    </div>
                  }
                  scrollableTarget="viewAllScrollContainerBar"
                  scrollThreshold={0.8}
                >
                  {allAccounts?.map((account, index) => (
                    <div className="approvedAcoutDetailsm" key={index}>
                      <div className="ApprovalAcdivm1">
                        <div className="Approvalsection1">
                          <div className="approvaltext1">
                            {account.name && account.name.length > 12 ? (
                              <p
                                title={account.name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              >
                                {account.name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </p>
                            ) : (
                              <p>
                                {account.name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </p>
                            )}
                            <span>{account.contact_no}</span>
                          </div>
                          <div className="approvaltext2">
                            <p className="z1">Name</p>
                            <span className="z2">Contact number</span>
                          </div>
                          <div className="approvaltext3">
                            {account.email_id &&
                            account.email_id.length > 12 ? (
                              <p
                                title={account.email_id
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              >
                                {account.email_id}
                              </p>
                            ) : (
                              <p>{account.email_id.toLowerCase()}</p>
                            )}
                            <span>Email ugsuag ID</span>
                          </div>
                          <div className="approvaltext4">
                            {account.category &&
                            account.category.length > 12 ? (
                              <p title={account.category}>{account.category}</p>
                            ) : (
                              <Tooltip
                                title={
                                  account.category
                                    ? account.category
                                    : "Not Assigned"
                                }
                                arrow
                              >
                                <p
                                  style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {account.category
                                    ? account.category
                                    : "Not Assigned"}
                                </p>
                              </Tooltip>
                            )}
                            <span>Assigned categories</span>
                          </div>
                        </div>
                      </div>

                      <div className="ApprovalAcdivm2 ">
                        <div className="Approvalsection2 p-[5px]  ">
                          <div className="approvalDiv1">
                            {account.bank_account_holder_full_name &&
                            account.bank_account_holder_full_name.length >
                              12 ? (
                              <p
                                title={account.bank_account_holder_full_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              >
                                {account.bank_account_holder_full_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </p>
                            ) : (
                              <p>
                                {account.bank_account_holder_full_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </p>
                            )}
                            <span className="approvalDiv1span mr-[5px]">
                              {account.pan_no}
                            </span>
                          </div>
                          <div className="approvalDiv2">
                            <span className="n1">Account holder name </span>
                            <span className="n2">PAN number</span>
                          </div>
                          <div className="approvalDiv3">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={account.bank_acc_no}
                            >
                              <p>{account.bank_acc_no}</p>
                            </Tooltip>
                            <span
                              style={{
                                marginRight: account.gstin_detail ? "62px" : "",
                              }}
                            >
                              {account.gstin_detail
                                ? account.gstin_detail
                                : "Not given"}{" "}
                            </span>
                          </div>
                          <div className="approvalDiv4">
                            <p>Account number</p>
                            <span>GSTIN</span>
                          </div>

                          <div className="approvalDiv5">
                            <div className="b">
                              <p>{account.ifsc_code}</p>
                              <span>IFSC code</span>
                            </div>
                            <div className="b1">
                              <img
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(account.ifsc_code))}
                                alt="bank"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="ApprovalAcdivm3">
                        <div className="Approvalsection3">
                          <div className="AprovalpageContainx1">
                            <p>{account.created_by_name}</p>
                            <span>{ConvertBefiDate(account.created_on)}</span>
                          </div>
                          <div className="AprovalpageContainx2">
                            <span className="b1">Requested by</span>
                            <span className="b2">Created on</span>
                          </div>

                          <div className="logo">
                            <div className="logo1">
                              {account.approval_status === "pending" &&
                                !loading[account.id] && (
                                  <>
                                    <button
                                      className="Approvalleftside"
                                      onClick={() =>
                                        handleOpenPopup(account.id)
                                      }
                                    >
                                      <img src={aprovaldenied} alt="" />
                                      <p>Reject</p>
                                    </button>

                                    <div className="approvaldivider">
                                      <p className="divider-sec"></p>
                                    </div>
                                    <button
                                      className="Approvalrightside"
                                      onClick={() => handleApproval(account.id)}
                                    >
                                      <img src={aprovalaccept} alt="" />
                                      <p>Approve</p>
                                    </button>
                                  </>
                                )}

                              {loading[account.id] && (
                                <div className="w-8 h-7 m-auto my-3">
                                  <img src={xpentraloader} alt="Loading..." />
                                </div>
                              )}

                              {account.approval_status === "Approved" &&
                                account.error === "false" &&
                                beneWaitingApprovalData &&
                                !loading[account.id] && (
                                  <div className="Approvedpage" id={account.id}>
                                    <div className="Approvedpage1">
                                      <img src={aprovalaccept} alt="" />
                                    </div>
                                    <div className="Approvedpage2">
                                      <span>Approved!</span>
                                      <p>Account has been approved.</p>
                                    </div>
                                  </div>
                                )}

                              {account.approval_status === "error" &&
                                account.error === "true" &&
                                !loading[account.id] && (
                                  <div className="Unknownpage">
                                    <div className="Unknownpage1">
                                      <img src={SkipVerification} alt="" />
                                    </div>
                                    <div className="Unknownpage2">
                                      <span>Failed to take action !</span>
                                      {account.message &&
                                      account.message.length >= 10 ? (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account?.message}
                                        >
                                          <p className="w-[70px] line-clamp-2 whitespace-wrap overflow-ellipsis text-[14px] text-[#FCFCFC] opacity-100">
                                            {account?.message}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        <p className="w-[70px] h-[50px] overflow-hidden whitespace-wrap overflow-ellipsis  text-[14px]  text-[#FCFCFC] opacity-100">
                                          {account?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )}

                              {account.approval_status === "rejected" &&
                                account.error === "false" &&
                                !loading[account.id] && (
                                  <div className="Declinedpage">
                                    <div className="Declinedpage1">
                                      <img src={aprovaldenied} alt="" />
                                    </div>
                                    <div className="Declinedpage2">
                                      <span>Rejected!</span>
                                      <p>Account has been Rejected.</p>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default RequireApproval;
