import React, { useState, useEffect, useRef } from "react";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import {
  beneCategories,
  beneWaitingApproved,
} from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import clear_filter from "../../assets/ViewAllTransactions/Clear filter.png";
import xpentraloader from "../../assets/Beneficiary Icons/lodemore.gif";
import ApproveWhite from "../../assets/Approve transactions/Approve white.png";
import { tooltipClasses, Tooltip } from "@mui/material";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import BalanceLoader from "../_utils/BalanceLoader";
import ReloadWhite from "../../assets/Approve transactions/Reload white.png";
import {
  getOwnerAccBalance,
  setshowsuccestoast,
} from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import DeclinedPopup from "../../containers/Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import SkipVerification from "../../assets/Approve transactions/DeclinedTxn_red.png";
import "./index.css";
import IfscCodeMapper from "../_utils/IfscLogoMapper";
import RefreshBalanceToast from "../_utils/RefreshBalanceToast";
import AnimationUpAndDown from "../BeneTransaction/OwnerPopup/AnimationUpAndDown";
import FormatAmount from "../_utils/FormatAmount/FormatAmount";
import { checkUsertoken } from "../../redux/features/login/login";
import { useNavigate } from "react-router-dom";

function BeneTxnSideDrawer({
  isOpen,
  onClose,
  txnRequiredStatus,
  DrawerOpenStatus,
  allAccounts,
  setAllAccounts,
  multitxnApprovedata,
  setMultiApproval,
  transactionFilterData,
  setTransactionFilterData,
  setIsSidebarOpen,
}) {
  const [ShowAccountName, setShowAccountName] = useState(false);
  const [BeneTxnSideDrawerLoader, SetbeneTxnSideDrawerLoder] = useState(false);
  const [DrawerData, setDrawerData] = useState({});
  const [showDeclinedPopup, setshowDeclinedPopup] = useState(false);
  const [deniedTxnId, SetdeniedTxnId] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [refreshId, SetrefreshId] = useState("");
  const [refreshAnimation, setrefreshAnimation] = useState("");
  const [PrevBalance, setPrevBalance] = useState(null);
  const { ownerAccBalanceLoading, ownerAccBalanceError } = useSelector(
    (state) => state.ownerAllVerification
  );
  const dispatch = useDispatch();

  const categoryDropdownRef = useRef(null);
  const settlementDropdownRef = useRef(null);

  useEffect(() => {
    dispatch(beneCategories());
  }, [dispatch]);
  const navigate = useNavigate();
  const { viewAccountDetailData } = useSelector((state) => state.verify);
  const { beneTxnAccountData } = useSelector(
    (state) => state?.beneTxnViewAccData
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target)
      ) {
      }

      if (
        settlementDropdownRef.current &&
        !settlementDropdownRef.current.contains(event.target)
      ) {
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (viewAccountDetailData) {
      setDrawerData(viewAccountDetailData?.beneficiary_accounts[0]);
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    if (DrawerOpenStatus === "BeneficiaryTxnMultiApproval") {
      if (ownerAccBalanceLoading) {
        RefreshBalanceToast();
      }
      if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
        setTimeout(() => {
          toast.dismiss();
        }, 150);
      }
    }
  }, [ownerAccBalanceLoading]);

  const additional_Data = beneTxnAccountData?.transaction[0]?.additional_data;

  const handleApproval = async (id) => {
    if (!sessionStorage.getItem("q2p_token")) {
      dispatch(checkUsertoken());
      setIsSidebarOpen(false);
      return;
    }
    dispatch(checkUsertoken());
    SetbeneTxnSideDrawerLoder(true);
    const data = await dispatch(beneWaitingApproved(id));
    let message1;
    if (data?.payload?.status === 500) {
      setTimeout(() => {
        toast.error(data?.payload?.error, toast_position);
      }, 1000);
    } else if (
      data &&
      data?.payload?.errors?.base?.[0] !== "Invalid token format"
    ) {
      const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
        (item) => {
          if (item.id === id) {
            let errorMessage = "";
            let lifecycleStatus = "";
            if (
              data.payload.status === true &&
              data.payload.beneficiary_accounts[0].approval_status ===
                "approved"
            ) {
              lifecycleStatus =
                data.payload.beneficiary_accounts[0].approval_status;
            }
            if (data?.payload?.errors?.user_unauthorized?.[0]) {
              setTimeout(() => {
                // toast.error(
                //   data?.payload?.errors?.user_unauthorized?.[0],
                //   toast_position
                // );
                navigate("/feature_not_assigned", {
                  state: {
                    unavailableService: "VBeneficiaryTxn",
                  },
                });
              }, 2000);
            } else if (
              data?.payload?.errors &&
              data?.payload?.errors?.bank_account_number[0] &&
              data?.payload?.errors?.permanent_account_number[0]
            ) {
              errorMessage = data?.payload?.errors?.bank_account_number[0];
              message1 = data?.payload?.errors?.permanent_account_number[0];
              setTimeout(() => {
                toast.error(errorMessage, toast_position);
              }, 1000);

              setTimeout(() => {
                toast.error(message1, toast_position);
              }, 1000);
            } else if (
              data?.payload?.errors?.bank_account_number &&
              data?.payload?.errors?.bank_account_number[0]
            ) {
              errorMessage = data?.payload?.errors?.bank_account_number[0];
              setTimeout(() => {
                toast.error(errorMessage, toast_position);
              }, 1000);
            }

            return {
              ...item,
              approval_status:
                // item.approval_status === "approved" ? "pending" : "approved",
                data?.payload.status === true &&
                item.approval_status === "pending"
                  ? lifecycleStatus
                  : "error",
              status: data?.payload.status,
              message: data?.payload.status === false ? errorMessage : "",
            };
          }
          return item;
        }
      );
      setDrawerData(updatedData[0]);
    }

    setTimeout(() => {
      SetbeneTxnSideDrawerLoder(false);
      setRefreshData(true);
    }, 2000);

    setTimeout(() => {
      if (data.payload.status === true) {
        window.location.reload();
      }
    }, 2000);
  };

  useEffect(() => {
    setRefreshData(false);
  }, [refreshData, dispatch]);

  const handleDeclinedTxn = (id) => {
    if (!sessionStorage.getItem("q2p_token")) {
      dispatch(checkUsertoken());
      setIsSidebarOpen(false);
      return;
    }
    setshowDeclinedPopup(true);
    setShowAccountName(true);
    SetdeniedTxnId(id);
  };
  const handleClosePopup = () => {
    if (!sessionStorage.getItem("q2p_token")) {
      setIsSidebarOpen(false);
      return;
    }
    setshowDeclinedPopup(false);
    setShowAccountName(false);
  };
  const handleRefresh = async (e, id, txn_id, prevBalance) => {
    e.stopPropagation();
    setPrevBalance(prevBalance);
    setrefreshAnimation(txn_id);
    SetrefreshId(id);

    try {
      let response = await dispatch(getOwnerAccBalance(id));
      if (response?.payload?.status === true) {
        let data = [multitxnApprovedata]?.map((item) => {
          if (
            response.payload.owner_accounts[0].owner_account_id ===
            item.owner_account_id
            // &&
            // item.txn_id === txn_id
          ) {
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response.payload.owner_accounts[0].owner_account_balance,
              owner_account_balance_last_fetched_at:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at,
            };
          }
          return item;
        });

        let responce = transactionFilterData?.map((item) => {
          if (
            response.payload.owner_accounts[0].owner_account_id ===
            item.owner_account_id
            // &&
            // item.txn_id === txn_id
          ) {
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response.payload.owner_accounts[0].owner_account_balance,
              owner_account_balance_last_fetched_at:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at,
            };
          }
          return item;
        });
        setMultiApproval(data[0]);
        if (setAllAccounts !== "beneside") {
          let responceData = allAccounts?.map((item) => {
            if (
              response.payload.owner_accounts[0].owner_account_id ===
              item.owner_account_id
              // &&
              // item.txn_id === txn_id
            ) {
              return {
                ...item,
                owner_account_balance_last_fetched_at_in_words:
                  response.payload.owner_accounts[0]
                    .owner_account_balance_last_fetched_at_in_words,
                owner_account_balance:
                  response.payload.owner_accounts[0].owner_account_balance,
                owner_account_balance_last_fetched_at:
                  response.payload.owner_accounts[0]
                    .owner_account_balance_last_fetched_at,
              };
            }
            return item;
          });

          setAllAccounts(responceData);
        }

        if (setAllAccounts === "beneside") {
          setTransactionFilterData(responce);
        }
        setTimeout(() => {
          toast.success("Balance refreshed successfully", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setshowsuccestoast());
        }, 250);
      } else {
        setTimeout(() => {
          toast.dismiss();
          toast.error(response?.error.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  const RequireApprovalDrawer = () => (
    <div className="bg-[#1D3A6D]" style={{ overflow: "hidden" }}>
      {/* bg-[#1D3A6D] */}
      {DrawerOpenStatus === "BeneTxnRequiredApprovalMoreDetails" && (
        <>
          <div className="beneTxnMoredetails flex  bg-[#314C7F] w-screen fixed  h-[208px] z-10 ">
            <div className="flex justify-between md:w-[27%]   lg:w-[27%]   xl:w-[24%] 4xl:w-[15%] 5xl:w-[10%]">
              <div className=" flex  items-center">
                <span className="ml-[30px] w-[118px] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#FFFFFF] mt-[110px]">
                  More detail
                </span>
              </div>
              <div className="flex  items-center  mt-[110px] ">
                <button
                  className=" more_button w-[30px] h-[30px] bg-[#1D3A6D] rounded-[5px] mr-5 "
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                >
                  <img
                    className="h-[15px] w-[15px]  ml-[7px] "
                    src={clear_filter}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div className=" w-full pb-[80px]  mt-[232px]  bg-[#1D3A6D]">
            <div className="multi-content-container h-[calc(100vh-232px)] ml-[30px] overflow-x-hidden overflow-y-auto pb-[120px]">
              <div>
                <p className="w-[auto] h-[22px text-left font-semibold text-[16px] leading-[22px] text-[#DADADA] mt-[10px]">
                  Verified Details
                </p>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px] text-left font-normal  lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[67px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Legal name
                  </span>
                </div>
                <div className=" mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px] font-normal lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[120px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Account holder name
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[204px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]?.pan_no}
                  </p>
                  <span className="w-[72px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    PAN number
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[80px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]
                      ?.gstin_detail
                      ? viewAccountDetailData.beneficiary_accounts[0]
                          .gstin_detail
                      : "Not given"}
                  </p>
                  <span className="w-[34px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    GSTIN
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              <div className="mt-[15px]">
                <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA]">
                  Contact Details
                </p>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={
                      viewAccountDetailData?.beneficiary_accounts[0]?.email_id
                    }
                  >
                    <p
                      id="email-id-firstLetter"
                      className="w-[227px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.email_id}
                    </p>
                  </Tooltip>
                  <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Email ID
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[124px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {`+91 ${viewAccountDetailData?.beneficiary_accounts[0]?.contact_no}`}
                  </p>
                  <span className="w-[91px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Contact number
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>

              {/* --------------------- */}
              <div className="mt-[15px]">
                <div>
                  <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA]">
                    Additional Details
                  </p>
                </div>

                {beneTxnAccountData?.transaction &&
                  beneTxnAccountData?.transaction?.map((account, idx) => (
                    <div key={idx} className="mt-[10px] flex-col gap-2">
                      {account?.invoice_no && (
                        <div className="flex flex-col">
                          <span className="text-[#FFFFFF] text-sm font-sans">
                            {account.invoice_no}
                          </span>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            Invoice number
                          </span>
                        </div>
                      )}
                      {account?.distribution_remark && (
                        <div className="flex flex-col mt-[10px]">
                          <span className="text-[#FFFFFF] text-sm font-sans">
                            {account?.distribution_remark}
                          </span>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            Distribution remark
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                {additional_Data &&
                additional_Data.data &&
                additional_Data.data.length > 0 ? (
                  additional_Data?.data?.map((data, index) => (
                    <React.Fragment key={index}>
                      {data?.name && (
                        <div className="flex flex-col mt-[10px]">
                          <p
                            className="text-[#FFFFFF] text-sm font-sans whitespace-nowrap overflow-hidden"
                            style={{ textOverflow: "ellipsis", width: "90%" }}
                          >
                            {data.value ? data.value : "Not given"}
                          </p>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            {data.name}
                          </span>
                        </div>
                      )}
                      {/* {data?.value && (
                        <div className="flex flex-col mt-[10px]">
                          <p className="text-[#FFFFFF] text-sm font-sans">
                            {data.value}
                          </p>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            Value
                          </span>
                        </div>
                      )} */}
                    </React.Fragment>
                  ))
                ) : (
                  <p className="w-[273px]  h-[44px] text-left text-[16px] leading-[22px] font-normal font-open-sans tracking-[0px] text-[#F9F9F9] opacity-100">
                    Additional details are not supported for the selected
                    category
                  </p>
                )}
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              {/* ------------------- */}
              <div className="mt-[15px]">
                <p className="w-[210px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                  {beneTxnAccountData?.transaction[0].txn_initiator}
                </p>
                <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                  Requested by
                </span>
              </div>
              {/* ----------------- */}
            </div>
          </div>
        </>
      )}
      {DrawerOpenStatus === "BeneficiaryTxnMultiApproval" && (
        <>
          <div className="beneTxnMoredetails flex  bg-[#314C7F] w-screen fixed  h-[208px] z-10">
            <div className="flex justify-between md:w-[27%]   lg:w-[27%]   xl:w-[24%] 4xl:w-[15%] 5xl:w-[10%]">
              <div className=" flex  items-center">
                <span className="ml-[30px] w-[118px] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#FFFFFF] mt-[110px]">
                  More details
                </span>
              </div>
              <div className="flex  items-center mt-[110px]">
                <button
                  className="more_button w-[30px] h-[30px] bg-[#1D3A6D] rounded-[5px] mr-5"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                >
                  <img
                    className="h-[15px] w-[15px]  ml-[7px]"
                    src={clear_filter}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>

          <div className=" w-full pb-[80px]  mt-[232px]  bg-[#1D3A6D]">
            <div
              className={`multi-content-container h-[calc(100vh-232px)] ml-[30px] overflow-x-hidden overflow-y-auto pb-[120px] `}
            >
              <div>
                <p className="w-[auto] h-[22px text-left font-semibold text-[16px] leading-[22px] text-[#DADADA] mt-[10px]">
                  Beneficiary Account Details
                </p>
                <div className="mt-[15px]">
                  <p className="w-[208px] h-[22px] text-left font-normal  lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-ellipsis">
                    {viewAccountDetailData?.beneficiary_accounts[0]
                      ?.bank_acc_no &&
                      viewAccountDetailData?.beneficiary_accounts[0]?.bank_acc_no.replace(
                        /(\d{4})(?=\d)/g,
                        "$1 "
                      )}
                  </p>
                  <span className="w-[67px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Account number
                  </span>
                </div>
                <div className=" mt-[15px]">
                  <div className="flex justify-between w-full h-[40px]  ">
                    <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ">
                      <p className="w-[208px] h-[22px]    font-normal  uppercase text-[16px] leading-[22px]  text-[#F9F9F9] overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {
                          viewAccountDetailData?.beneficiary_accounts[0]
                            ?.ifsc_code
                        }
                      </p>
                      <span className="w-[120px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                        IFSC code
                      </span>
                    </div>

                    <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                      <img
                        className="w-[79px] h-[20px] relative top-[9px] left-[11px]"
                        src={require("../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            viewAccountDetailData?.beneficiary_accounts[0]
                              ?.ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                    <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                  </div>
                  {/* <p className="w-[208px] h-[22px]    font-normal    lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {viewAccountDetailData?.beneficiary_accounts[0]?.ifsc_code}
                  </p>
                  <span className="w-[120px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    IFSC code
                  </span> */}
                </div>
                <div className="mt-[15px]">
                  <p className="w-[204px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {
                      viewAccountDetailData?.beneficiary_accounts[0]
                        ?.approval_status
                    }
                  </p>
                  <span className="w-[72px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Account status
                  </span>
                </div>

                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              <div>
                <p className="w-[203px] h-[22px text-left font-semibold text-[16px] leading-[22px] text-[#DADADA] mt-[10px]">
                  Beneficiary Category
                </p>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={multitxnApprovedata?.category}
                  >
                    <p className="w-[208px] h-[22px] text-left font-normal  lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] overflow-hidden whitespace-nowrap overflow-ellipsis">
                      {multitxnApprovedata?.category}
                    </p>
                  </Tooltip>
                  <span className="w-[67px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Category selected
                  </span>
                </div>

                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              <div>
                <p className="w-[auto] h-[22px text-left font-semibold text-[16px] leading-[22px] text-[#DADADA] mt-[10px]">
                  Verified Details
                </p>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px] text-left font-normal  lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[67px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Legal name
                  </span>
                </div>
                <div className=" mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px] font-normal lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[120px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Account holder name
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[204px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]?.pan_no}
                  </p>
                  <span className="w-[72px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    PAN number
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[80px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]
                      ?.gstin_detail
                      ? viewAccountDetailData.beneficiary_accounts[0]
                          .gstin_detail
                      : "Not given"}
                  </p>
                  <span className="w-[34px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    GSTIN
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              <div className="mt-[15px]">
                <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA]">
                  Contact Details
                </p>
                <div className="mt-[15px]">
                  {viewAccountDetailData?.beneficiary_accounts[0]?.email_id &&
                  viewAccountDetailData?.beneficiary_accounts[0]?.email_id
                    .length >= 15 ? (
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={
                        viewAccountDetailData?.beneficiary_accounts[0]?.email_id
                      }
                    >
                      <p
                        id="email-id-firstLetter"
                        className="w-[100px]  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#FCFCFC] opacity-100"
                      >
                        {
                          viewAccountDetailData?.beneficiary_accounts[0]
                            ?.email_id
                        }
                      </p>
                    </Tooltip>
                  ) : (
                    <p
                      id="email-id-firstLetter"
                      className="w-[117px] font-normal   text-[14px] leading-19 text-[#FCFCFC] whitespace-nowrap overflow-hidden overflow-100"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.email_id}
                    </p>
                  )}
                  {/* <p className="w-[227px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]?.email_id}
                  </p> */}
                  <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Email ID
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[124px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {`+91 ${viewAccountDetailData?.beneficiary_accounts[0]?.contact_no}`}
                  </p>
                  <span className="w-[91px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Contact number
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>

              {/* --------------------- */}
              <div className="mt-[15px]">
                <div>
                  <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA]">
                    Additional Details
                  </p>
                </div>
                {/* {beneTxnAccountData?.transaction[0]?.additional_data?.data.map((account, idx)=>)} */}
                {beneTxnAccountData?.transaction &&
                  beneTxnAccountData?.transaction?.map((account, idx) => (
                    <div key={idx} className="mt-[10px] flex-col gap-2">
                      {account?.invoice_no && (
                        <div className="flex flex-col">
                          <span className="text-[#FFFFFF] text-sm font-sans">
                            {account.invoice_no}
                          </span>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            Invoice number
                          </span>
                        </div>
                      )}
                      {account?.distribution_remark && (
                        <div className="flex flex-col mt-[10px]">
                          <span className="text-[#FFFFFF] text-sm font-sans">
                            {account?.distribution_remark}
                          </span>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            Distribution remark
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                {additional_Data &&
                additional_Data.data &&
                additional_Data.data.length > 0 ? (
                  additional_Data?.data?.map((data, index) => (
                    <React.Fragment key={index}>
                      {data?.name && (
                        <div className="flex flex-col mt-[10px]">
                          <p
                            className="text-[#FFFFFF] text-sm font-sans whitespace-nowrap overflow-hidden"
                            style={{ textOverflow: "ellipsis", width: "90%" }}
                          >
                            {data.value ? data.value : "Not given"}
                          </p>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            {data.name}
                          </span>
                        </div>
                      )}
                      {/* {data?.value && (
                        <div className="flex flex-col mt-[10px]">
                          <p className="text-[#FFFFFF] text-sm font-sans">
                            {data.value}
                          </p>
                          <span className="text-[#C9C9C9] text-xs font-sans">
                            Value
                          </span>
                        </div>
                      )} */}
                    </React.Fragment>
                  ))
                ) : (
                  <p className="w-[273px]  h-[44px] text-left text-[16px] leading-[22px] font-normal font-open-sans tracking-[0px] text-[#F9F9F9] opacity-100">
                    Additional details are not supported for the selected
                    category
                  </p>
                )}
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              {/* ------------------- */}
              <div className="mt-[15px]">
                <p className="w-[210px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                  {beneTxnAccountData?.transaction[0].txn_initiator}
                </p>
                <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                  Requested by
                </span>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              {/* ----------------- */}
              <div>
                <p className="w-[auto] h-[22px text-left font-semibold text-[16px] leading-[22px] text-[#DADADA] mt-[10px]">
                  Source Account
                </p>
                <div className="mt-[15px]">
                  <p className="w-[208px] h-[22px] text-left font-normal lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-ellipsis">
                    {/* {beneTxnAccountData?.transaction[0]
                      ?.owner_account_bank_acc_no &&
                      beneTxnAccountData?.transaction[0]?.owner_account_bank_acc_no.replace(
                        /^(.{12})(.*)$/,
                        (_, first12, rest) =>
                          first12
                            .replace(/\d/g, "•")
                            .replace(/(.{4})/g, "$1 ") + rest
                      )} */}

                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={
                        beneTxnAccountData?.transaction[0]
                          ?.owner_account_bank_acc_no
                      }
                    >
                      <div className="left-side-div">
                        <p>
                          {beneTxnAccountData?.transaction[0]?.owner_account_bank_acc_no.substring(
                            0,
                            4
                          ) +
                            " •••• " +
                            beneTxnAccountData?.transaction[0]?.owner_account_bank_acc_no.substring(
                              beneTxnAccountData?.transaction[0]
                                ?.owner_account_bank_acc_no.length - 4
                            )}
                        </p>
                      </div>
                    </Tooltip>
                  </p>

                  <span className="w-[67px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Account number
                  </span>
                </div>
                <div className=" mt-[15px]">
                  <div className="flex  items-center">
                    <div className="flex gap-1">
                      <div>
                        <h4
                          style={{
                            color:
                              multitxnApprovedata?.owner_account_balance >=
                              multitxnApprovedata.amount
                                ? "#4DDD37"
                                : "#FF5860",
                          }}
                        >
                          {" "}
                          ₹{" "}
                          {
                            <FormatAmount
                              price={multitxnApprovedata?.owner_account_balance}
                            />
                          }
                        </h4>
                      </div>
                      <div className=" relative">
                        <p className="">
                          <AnimationUpAndDown
                            loader={ownerAccBalanceLoading}
                            currentId={multitxnApprovedata?.txn_id}
                            currentBalance={
                              multitxnApprovedata?.owner_account_balance
                            }
                            previousId={refreshAnimation}
                            previousBalance={PrevBalance}
                            refreshType={"single"}
                            classType={"sideDrawer"}
                          />
                        </p>
                      </div>
                      <div className=" ml-3">
                        <p className="h-[26px] text-[#C9C9C9]"> {"  "} |</p>
                      </div>
                    </div>

                    <div
                      onClick={(e) => {
                        // SetrefreshId(multitxnApprovedata?.owner_account_id);
                        handleRefresh(
                          e,
                          multitxnApprovedata?.owner_account_id,
                          multitxnApprovedata?.txn_id,
                          multitxnApprovedata?.owner_account_balance
                        );
                      }}
                      className="flex items-center cursor-pointer  hover:bg-custom-cardHover ml-[4px] gap-[5px]"
                    >
                      {ownerAccBalanceLoading &&
                      refreshId === multitxnApprovedata?.owner_account_id ? (
                        <BalanceLoader
                          loader={ownerAccBalanceLoading}
                          type={"createTxn"}
                        />
                      ) : !ownerAccBalanceLoading &&
                        refreshId === multitxnApprovedata?.owner_account_id ? (
                        <BalanceLoader
                          loader={ownerAccBalanceLoading}
                          setAccId={SetrefreshId}
                          type={"createTxn"}
                        />
                      ) : (
                        <>
                          <img
                            className="w-[12px] h-[12px] opacity-0.7"
                            src={ReloadWhite}
                            alt=""
                          />
                          <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#C9C9C9] opacity-100">
                            Refresh
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {/* <p className="w-[208px] h-[22px]    font-normal    lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {
                      viewAccountDetailData?.beneficiary_accounts[0]
                        ?.bank_account_holder_full_name
                    }
                  </p> */}
                  <span className="w-[120px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Balance -{" "}
                    {multitxnApprovedata &&
                      multitxnApprovedata?.owner_account_balance_last_fetched_at_in_words}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {DrawerOpenStatus === "BeneficiaryTxnMultiApprovalPending" && (
        <>
          <div className="beneTxnMoredetails flex bg-[#314C7F] w-screen fixed h-[208px] z-10">
            <div className="flex justify-between md:w-[27%] lg:w-[27%] xl:w-[24%] 4xl:w-[15%] 5xl:w-[10%]">
              <div className="flex items-center">
                <span className="ml-[30px] w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#FFFFFF] mt-[110px]">
                  Account details
                </span>
              </div>
              <div className="flex items-center mt-[110px]">
                <button
                  className="more_button w-[30px] h-[30px] bg-[#1D3A6D] rounded-[5px] mr-5"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                >
                  <img
                    className="h-[15px] w-[15px] ml-[7px]"
                    src={clear_filter}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="w-full mt-[232px] bg-[#1D3A6D]">
            <div className="multi-content-container h-[calc(100vh-232px)] ml-[30px] overflow-x-hidden overflow-y-auto pb-[200px]">
              <div>
                <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA] mt-[10px]">
                  Verified Details
                </p>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px] text-left font-normal lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[67px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Legal name
                  </span>
                </div>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px] font-normal lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] overflow-hidden whitespace-nowrap"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[120px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Account holder name
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[204px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]?.pan_no}
                  </p>
                  <span className="w-[72px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    PAN number
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[80px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]
                      ?.gstin_detail
                      ? viewAccountDetailData.beneficiary_accounts[0]
                          .gstin_detail
                      : "Not given"}
                  </p>
                  <span className="w-[34px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    GSTIN
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              <div className="mt-[15px]">
                <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA]">
                  Contact Details
                </p>
                <div className="mt-[15px]">
                  {viewAccountDetailData?.beneficiary_accounts[0]?.email_id &&
                  viewAccountDetailData?.beneficiary_accounts[0]?.email_id
                    .length >= 15 ? (
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={
                        viewAccountDetailData?.beneficiary_accounts[0]?.email_id
                      }
                    >
                      <p
                        id="email-id-firstLetter"
                        className="w-[100px] overflow-hidden whitespace-nowrap font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100"
                        style={{ textOverflow: "ellipsis", width: "90%" }}
                      >
                        {
                          viewAccountDetailData?.beneficiary_accounts[0]
                            ?.email_id
                        }
                      </p>
                    </Tooltip>
                  ) : (
                    <p
                      id="email-id-firstLetter"
                      className="w-[117px] font-normal text-[14px] leading-19 text-[#FCFCFC] opacity-100"
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.email_id}
                    </p>
                  )}
                  <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Email ID
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[124px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {`+91 ${viewAccountDetailData?.beneficiary_accounts[0]?.contact_no}`}
                  </p>
                  <span className="w-[91px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Contact number
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              <div className="mt-[15px]">
                <p className="w-[210px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                  {beneTxnAccountData?.transaction[0].txn_initiator}
                </p>
                <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                  Requested by
                </span>
              </div>
            </div>
          </div>

          <div className="w-[26.1rem] fixed z-20 h-[93px] bg-[#314C7F] 4xl:w-[15%] 5xl:w-[10%] bottom-0 gap-10 rounded-tl-[25px] rounded-tr-[25px] shadow-customShadow">
            <div
              style={{
                background: BeneTxnSideDrawerLoader ? "#AE9041" : "#314C7F",
              }}
              className="w-[26.1rem] fixed z-20 h-[93px] 4xl:w-[15%] 5xl:w-[10%] bottom-0 gap-10 rounded-tl-[25px] rounded-tr-[25px] shadow-customShadow"
            >
              {DrawerData?.approval_status === "pending" &&
                !BeneTxnSideDrawerLoader && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      onClick={() => handleDeclinedTxn(DrawerData?.id)}
                      className="flex justify-center items-center gap-[4px] w-[140px] h-[42px] bg-[#E9545B] rounded-[20px] mt-[10px] cursor-pointer"
                    >
                      <img
                        className="w-[12px] h-[12px]"
                        src={clear_filter}
                        alt=""
                      />
                      <p className="w-[56px] h-[22px] text-center font-normal text-[16px] leading-[22px] tracking-normal text-[#FAFAFA]">
                        Reject
                      </p>
                    </div>
                    <div
                      className="flex justify-center items-center gap-[4px] w-[140px] h-[42px] bg-[#69B76F] rounded-[20px] mt-[10px] cursor-pointer"
                      onClick={() => handleApproval(DrawerData?.id)}
                    >
                      <img
                        className="w-[16px] h-[16px]"
                        src={ApproveWhite}
                        alt=""
                      />
                      <p className="w-[62px] h-[22px] text-center font-normal text-[16px] leading-[22px] tracking-normal text-[#FAFAFA]">
                        Approve
                      </p>
                    </div>
                  </div>
                )}
              {BeneTxnSideDrawerLoader && (
                <div className="w-full flex justify-center">
                  <div className="w-[188px] h-[27px] flex mt-[30px] justify-center items-center gap-[4px]">
                    <img
                      className="w-[45px] h-[45px]"
                      src={xpentraloader}
                      alt=""
                    />
                    <p className="w-[149px] h-[22px] text-left font-normal text-[14px] leading-[22px] text-[#FAFAFA]">
                      Approving account
                    </p>
                  </div>
                </div>
              )}
              {DrawerData.approval_status === "approved" &&
                DrawerData.status === true &&
                !BeneTxnSideDrawerLoader && (
                  <div className="w-full h-full rounded-tl-[25px] rounded-tr-[25px] flex justify-center bg-[#69B76F] shadow-customShadow">
                    <div className="w-[163px] h-[22px] flex mt-[30px] justify-center items-center gap-[4px]">
                      <img
                        className="w-[16px] h-[16px]"
                        src={ApproveWhite}
                        alt=""
                      />
                      <p className="w-[149px] h-[22px] text-left font-normal text-[14px] leading-[22px] text-[#FAFAFA]">
                        Account Approved
                      </p>
                    </div>
                  </div>
                )}
              {DrawerData.approval_status === "denied" &&
                DrawerData.status === true &&
                !BeneTxnSideDrawerLoader && (
                  <div className="w-full h-full rounded-tl-[25px] rounded-tr-[25px] flex justify-center bg-[#F05A6A] shadow-customShadow">
                    <div className="w-[163px] h-[22px] flex mt-[15px] justify-center items-center gap-[6px]">
                      <img
                        className="w-[12px] h-[12px]"
                        src={clear_filter}
                        alt=""
                      />
                      <p className="w-[130px] h-[22px] text-left font-normal text-[14px] leading-[22px] text-[#FAFAFA]">
                        Account Rejected
                      </p>
                    </div>
                  </div>
                )}
              {DrawerData.approval_status === "error" &&
                !BeneTxnSideDrawerLoader && (
                  <div className="w-full h-full rounded-tl-[25px] rounded-tr-[25px] flex justify-center bg-[#BB5353] shadow-customShadow">
                    <div className="w-[300px] flex mt-[15px] gap-[10px]">
                      <img
                        className="w-[30px] h-[30px]"
                        src={SkipVerification}
                        alt=""
                      />
                      <div className="w-[300px] mb-6">
                        <p className="w-[156px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] text-white opacity-100">
                          Failed to take action
                        </p>
                        <p className="pb-[120px] font-sans font-normal text-[12px] leading-normal text-[#FAFAFA] opacity-100">
                          {DrawerData.message}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}

      {/* ---------------- */}
      {DrawerOpenStatus === "BeneTxnRequiredApproval" && (
        //  div className="beneTxnMoredetails flex  bg-[#314C7F]  w-screen fixed  h-[208px] z-10">
        //     <div className="flex justify-between md:w-[27%]   lg:w-[27%]  xl:w-[27%]  2xl:w-[27%]   3xl:w-[27%]   4xl:w-[15%] 5xl:w-[10%]">
        //       <div className=" flex  items-center">
        //         <span className="ml-[30px] w-[118px] h-[22px]  text-left font-semibold text-[16px] leading-[22px] text-[#FFFFFF] mt-[110px]">
        //           Account details
        //         </span>
        <>
          <div className="beneTxnMoredetails flex  bg-[#314C7F]  w-screen fixed  h-[208px] z-10">
            <div className="flex justify-between md:w-[25%]       lg:w-[24%]  xl:w-[24%]      2xl:w-[24%]   3xl:w-[24%]   4xl:w-[15%] 5xl:w-[10%]">
              <div className=" flex  items-center">
                <span className="ml-[30px] w-[auto] h-[22px]  text-left font-semibold text-[16px] leading-[22px] text-[#FFFFFF] mt-[110px]">
                  Account details
                </span>
              </div>
              <div className="flex  items-center mt-[110px]">
                <button
                  className="more_button w-[30px] h-[30px] bg-[#1D3A6D]   rounded-[5px] mr-5"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                >
                  <img
                    className="h-[15px] w-[15px]  ml-[7px]"
                    src={clear_filter}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>

          <div className=" w-full mt-[232px] bg-[#1D3A6D]">
            <div className="content-container h-[calc(100vh-232px)] ml-[30px] overflow-x-hidden overflow-y-auto pb-[200px]">
              <div>
                <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA] mt-[10px] ">
                  Verified Details
                </p>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px] text-left font-normal  lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9]  whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[67px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Legal name
                  </span>
                </div>
                <div className=" mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  >
                    <p
                      className="w-[208px] h-[22px]    font-normal    lowercase text-[16px] leading-[22px] first-line:capitalize text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.bank_account_holder_full_name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </p>
                  </Tooltip>
                  <span className="w-[120px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Account holder name
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[204px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]?.pan_no}
                  </p>
                  <span className="w-[72px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    PAN number
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[80px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {viewAccountDetailData?.beneficiary_accounts[0]
                      ?.gstin_detail
                      ? viewAccountDetailData.beneficiary_accounts[0]
                          .gstin_detail
                      : "Not given"}
                  </p>
                  <span className="w-[34px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    GSTIN
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>
              <div className="mt-[15px]">
                <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] leading-[22px] text-[#DADADA]">
                  Contact Details
                </p>
                <div className="mt-[15px]">
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={
                      viewAccountDetailData?.beneficiary_accounts[0]?.email_id
                    }
                  >
                    <p
                      id="email-id-firstLetter"
                      className="w-[227px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9] whitespace-nowrap overflow-hidden"
                      style={{ textOverflow: "ellipsis", width: "90%" }}
                    >
                      {viewAccountDetailData?.beneficiary_accounts[0]?.email_id}
                    </p>
                  </Tooltip>
                  <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Email ID
                  </span>
                </div>
                <div className="mt-[15px]">
                  <p className="w-[124px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                    {`+91 ${viewAccountDetailData?.beneficiary_accounts[0]?.contact_no}`}
                  </p>
                  <span className="w-[91px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Contact number
                  </span>
                </div>
                <div className="mt-[15px] w-[298px] h-0 border-[1px] border-solid border-[#031F4F] opacity-100"></div>
              </div>

              {/* --------------------- */}

              {/* ------------------- */}
              <div className="mt-[15px]">
                <p className="w-[210px] h-[22px] text-left font-normal text-[16px] leading-[22px] text-[#F9F9F9]">
                  {beneTxnAccountData?.transaction[0].txn_initiator}
                </p>
                <span className="w-[46px] h-[17px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                  Requested by
                </span>
              </div>
              {/* ----------------- */}
            </div>
          </div>
          {/* bg-[#314C7F] */}
          <div
            style={{
              background: BeneTxnSideDrawerLoader ? "#AE9041" : "#314C7F",
            }}
            className=" w-[26.1rem]   fixed z-20 h-[100px] bg-[#314C7F] 4xl:w-[15%]  5xl:w-[10%] bottom-0  gap-10 rounded-tl-[25px] rounded-tr-[25px]   shadow-customShadow"
          >
            <div className="  w-[26.1rem]   fixed z-20 h-[110px]  2xl:w-[23.1rem] 3.1xl:w-[23.1rem]   4xl:w-[15%]  5xl:w-[10%] bottom-0   gap-10 rounded-tl-[25px] rounded-tr-[25px]   shadow-customShadow">
              {DrawerData?.approval_status === "pending" &&
                !BeneTxnSideDrawerLoader && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <div
                      onClick={() => handleDeclinedTxn(DrawerData?.id)}
                      className="flex  justify-center items-center gap-[4px]   w-[140px] h-[42px] bg-[#E9545B] rounded-[20px] mt-[10px] cursor-pointer"
                    >
                      <img
                        className="w-[12px] h-[12px]"
                        src={clear_filter}
                        alt=""
                      />
                      <p className="w-[56px] h-[22px] text-center font-normal text-[16px] leading-[22px] tracking-normal text-[#FAFAFA]">
                        Reject
                      </p>
                    </div>
                    <div
                      className="flex justify-center items-center gap-[4px] w-[140px] h-[42px] bg-[#69B76F] rounded-[20px]  mt-[10px] cursor-pointer"
                      onClick={() => handleApproval(DrawerData?.id)}
                    >
                      <img
                        className="w-[16px] h-[16px]"
                        src={ApproveWhite}
                        alt=""
                      />
                      <p className="w-[62px] h-[22px] text-center font-normal text-[16px] leading-[22px] tracking-normal text-[#FAFAFA]">
                        Approve
                      </p>
                    </div>
                  </div>
                )}
              {BeneTxnSideDrawerLoader && (
                <div className=" w-full flex justify-center">
                  <div className="w-[188px] h-[27px] flex mt-[30px]  justify-center items-center gap-[4px]">
                    <img
                      className="w-[45px] h-[45px]"
                      src={xpentraloader}
                      alt=""
                    />
                    <p className="w-[149px] h-[22px] text-left font-normal  text-[14px] leading-[22px] text-[#FAFAFA]">
                      Approving account
                    </p>
                  </div>{" "}
                </div>
              )}
              {DrawerData.approval_status === "approved" &&
                DrawerData.status === true &&
                !BeneTxnSideDrawerLoader && (
                  <div className="w-full h-full rounded-tl-[25px] rounded-tr-[25px]  flex justify-center  bg-[#69B76F] shadow-customShadow">
                    <div className="w-[163px] h-[22px] flex mt-[30px]  justify-center items-center gap-[4px]">
                      <img
                        className="w-[16px] h-[16px]"
                        src={ApproveWhite}
                        alt=""
                      />
                      <p className="w-[149px] h-[22px] text-left font-normal  text-[14px] leading-[22px] text-[#FAFAFA]">
                        Account Approved
                      </p>
                    </div>
                  </div>
                )}
              {DrawerData.approval_status === "denied" &&
                DrawerData.status === true &&
                !BeneTxnSideDrawerLoader && (
                  <div className="w-full h-full rounded-tl-[25px] rounded-tr-[25px]  flex justify-center  bg-[#F05A6A] shadow-customShadow">
                    <div className="w-[163px] h-[22px] flex mt-[15px]  justify-center items-center gap-[6px]">
                      <img
                        className="w-[12px] h-[12px]"
                        src={clear_filter}
                        alt=""
                      />
                      <p className="w-[130px] h-[22px] text-left font-normal  text-[14px] leading-[22px] text-[#FAFAFA]">
                        Account Rejected
                      </p>
                    </div>
                  </div>
                )}

              {DrawerData.approval_status === "error" &&
                !BeneTxnSideDrawerLoader && (
                  <div className="w-full h-full rounded-tl-[25px] rounded-tr-[25px]   flex justify-center  bg-[#BB5353] shadow-customShadow">
                    <div className=" w-[300px]  flex mt-[15px]    gap-[10px]">
                      <img
                        className="w-[30px] h-[30px]"
                        src={SkipVerification}
                        alt=""
                      />
                      <div className="w-[300px] mb-3">
                        <p className="w-[156px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] text-white opacity-100">
                          Failed to take action
                        </p>
                        <p className="  pb-[120px]   font-sans font-normal text-[12px] leading-normal text-[#FAFAFA] opacity-100">
                          {DrawerData.message}
                        </p>
                      </div>
                      {/* <div>
                      <span className="w-[114px] h-[22px] text-left font-normal  text-[14px] leading-[22px] text-[#FAFAFA] overflow-hidden  overflow-ellipsis">
                        {DrawerData.message}
                      </span>
                    </div> */}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <Drawer
      className="custombackdrop"
      anchor="right"
      open={isOpen}
      // onClose={toggleDrawer(false)}
      transitionDuration={300}
      sx={{ zIndex: 100 }}
      PaperProps={{
        sx: {
          width:
            txnRequiredStatus === "BeneTxnRequiredApproval" ||
            txnRequiredStatus === "BeneficiaryTxnMultiApproval"
              ? "24%"
              : "25%",
          "@media (min-width: 2560px)": {
            width: "15%",
          },
          "@media (min-width: 3840px)": {
            width: "10%",
          },
          background:
            txnRequiredStatus === "BeneTxnRequiredApproval" ||
            txnRequiredStatus === "BeneficiaryTxnMultiApproval"
              ? "#1D3A6D"
              : "inherit",
          overflowX:
            txnRequiredStatus === "BeneTxnRequiredApproval" ||
            txnRequiredStatus === "BeneficiaryTxnMultiApproval"
              ? "hidden"
              : "",
        },
      }}
    >
      {txnRequiredStatus === "BeneTxnRequiredApproval" ||
      txnRequiredStatus === "BeneficiaryTxnMultiApproval"
        ? showDeclinedPopup && (
            <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
              <DeclinedPopup
                onClose={handleClosePopup}
                accountId={deniedTxnId}
                allAccounts={DrawerData}
                updateAllAccounts={setDrawerData}
                status={"Transaction"}
                sideDrawer={"SideDrawer"}
                ShowAccountName={ShowAccountName}
                setIsSidebarOpen={setIsSidebarOpen}
              />
            </div>
          )
        : null}

      {txnRequiredStatus === "BeneTxnRequiredApproval" ||
      txnRequiredStatus === "BeneficiaryTxnMultiApproval"
        ? RequireApprovalDrawer()
        : ""}
    </Drawer>
  );
}

export default BeneTxnSideDrawer;
