import React, { useEffect, useRef } from "react";
import "./index.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { beneCreateTxn } from "../../../redux/features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import { checkUsertoken } from "../../../redux/features/login/login";

const BeneTxnStepper = ({
  activeStep,
  handleBack,
  steps,
  handleNext,
  nextButtonEnabled,
  isClicked,
  isButtonDisabled,
  amount,
  beneAccountId,
  ownerId,
  categories,
  additionalData,
  settleType,
  transactionDisable,
  setTransactionDisable,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const spaceKeyPressed = useRef(false);

  const storeData = categories?.configuration?.data?.map((val) => {
    if (additionalData[val?.name]) {
      const dataValue = additionalData[val?.name];
      if (val?.data_type === "numeric") {
        const integerValue = parseInt(dataValue, 10);
        if (!isNaN(integerValue)) {
          return { ...val, value: integerValue };
        } else {
          throw new Error(
            `Value '${dataValue}' is not a valid integer for category '${val?.name}'`
          );
        }
      } else {
        return { ...val, value: dataValue };
      }
    }
    return val;
  });

  const throttle = (func, delay) => {
    let inThrottle = false;
    return function (...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), delay);
      }
    };
  };

  const handleEnterKeyPress = (e) => {
    if (e.code === "Space") {
      spaceKeyPressed.current = true;
      return;
    }

    if (e.key === "Enter" && activeStep === 3 && !spaceKeyPressed.current) {
      throttledHandleCreateTransaction();
    }

    if (e.key !== "Space") {
      spaceKeyPressed.current = false;
    }
  };

  const handleCreateTransaction = async () => {
    if (spaceKeyPressed.current) {
      spaceKeyPressed.current = false;
      return;
    }

    dispatch(checkUsertoken());
    const response1 = await dispatch(
      beneCreateTxn({
        amount: amount,
        owner_account_id: ownerId,
        beneficiary_account_id: beneAccountId,
        category: categories?.name,
        additional_data: storeData,
        payment_method: settleType.toUpperCase(),
      })
    );
    console.log(response1, "response1");
    if (response1?.error?.message === "Rejected") {
      setTimeout(() => {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "VBeneficiaryTxn",
          },
        });
      }, 1000);
    } else {
      if (response1?.type === "beneCreateTxn/fulfilled") {
        setTransactionDisable(true);
      }

      let transactionId = response1.payload?.transaction[0]?.txn_id;
      navigate(
        `/transaction/beneficiary_transaction/success/${transactionId}`,
        {
          replace: true,
        }
      );
      setTimeout(() => {
        setTransactionDisable(false);
      }, 1000);
    }
  };

  const throttledHandleCreateTransaction = throttle(
    handleCreateTransaction,
    5000
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, []);

  return (
    <div className="bene_Stepper_Main">
      <Button
        sx={{
          borderRadius: 0,
        }}
        disabled={activeStep === 0 || isButtonDisabled}
        className="custom_txn"
        style={{
          opacity: activeStep === 0 ? "0.4" : "1",
        }}
        onClick={handleBack}
      >
        Previous
      </Button>
      <div className="stepperline_Div_txn">
        {steps?.map((val, index) => {
          return (
            <div className="stepinnerline" key={index}>
              <div
                className={
                  activeStep === index && isClicked
                    ? "stepDetails growing-element"
                    : "stepDetails"
                }
                style={{
                  opacity: activeStep === index ? "1" : "0",
                }}
              >
                {activeStep === index ? val : "Hello"}
              </div>
              <div
                className={
                  activeStep === index && isClicked
                    ? "main_Line growing-element"
                    : "main_Line"
                }
                style={{
                  width: activeStep === index ? "220px" : "100px",
                  opacity: activeStep === index ? "1" : "0.5",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {activeStep === 3 ? (
        <Button
          disabled={transactionDisable}
          className="create_Button"
          sx={{
            borderRadius: 0,
          }}
          onClick={throttledHandleCreateTransaction}
        >
          Create
        </Button>
      ) : (
        <Button
          sx={{
            borderRadius: 0,
          }}
          disabled={nextButtonEnabled || isButtonDisabled}
          style={{
            backgroundColor: nextButtonEnabled ? "#656565" : "#F9F9F9",
            color: nextButtonEnabled ? "#FFFFFF" : "#1D3A6D",
          }}
          className="custom_txn_next"
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </div>
  );
};
export default BeneTxnStepper;
